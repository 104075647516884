// base url
// export const URL = "http://18.220.253.162/wastenotapi";
// export const URL = "http://localhost:3005/foodapi";
export const URL = "https://wastenwantn.com/wastenotapi";

// apis endpoint
export const LOGIN = "user/login";
export const GET_USERS = "user/profile";
export const VALIDATE_EMAIL = "user/validateEmail";
export const GET_DOCUMENTS = "document/all";
export const DELETE_USER = "admin/deleteuser";
export const ADD_DOCUMENT = "document";
export const DELETE_DOCUMENT = "document/delete";
export const GET_ORGANIZATION = "user/organization/all";
export const GET_BUSINESS = "user/business/all";
export const REGISTER = "user/signup";
export const GET_PROFILE = "admin/updateuser";
export const GET_ADS = "ad";
export const CONNECT = "user/connect";
export const MESSAGE = "user/message";
export const RESET_PASSWORD = "user/resetPassword";
export const VALIDATE_CODE = "user/validateCode";
export const CHANGE_PASSWORD = "user/changePassword";
export const UPLOAD_PROFILE = "user/profilePhoto";
export const UPLOAD_DOCUMENT = "/document/pdfUpload";
export const ADS_UPDATE = "ad/update";
export const ADMIN_CHANGE_PASSWORD = "user/admin/changePassword";
export const DELETE_ADS = "ad/delete ";
export const CONNECTED_BUSINESS = "user/business/connected";
export const GET_DISPLAY_ADS = "ad/display";
export const UPLOAD_BANNER = "ad/bannerImage";
