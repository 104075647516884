import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/actions/dashboardAction";
import { showDataPerPage } from "../../utils/functions";
import Pagination from "./Pagination";
import { usePagination } from "../../apis/hooks";

const Users = () => {
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState(0);
  const [csvData, setCSVData] = useState([]);
  const csvLinkRef = useRef(null);

  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  const pagination = usePagination(users?.totalCount, showDataPerPage);

  useEffect(() => {
    if (pagination.current > 0) {
      dispatch(getAllUsers(pagination.current, type));
    }
  }, [pagination.current, type]);

  const csvDataHandler = async () => {
    const allUsers = [];
    for (let page = 1; page <= users.totalPages; page++) {
      const { users } = await dispatch(getAllUsers(page, type, false));
      allUsers.push(...users);
    }

    if (allUsers?.length) {
      const data = allUsers
        ?.filter((user) => (type ? user.type == type : user))
        .map((user) => [
          user.name,
          user.email,
          user.address,
          user.phone,
          user?.type === 1
            ? "Organization"
            : user?.type === 2
            ? "User"
            : user?.type === 3
            ? "Admin"
            : "",
        ]);

      setCSVData([["Name", "Email", "Address", "Phone", "Type"], ...data]);
    }
  };

  useEffect(() => {
    if (csvData?.length) {
      csvLinkRef.current.link.click();
    }
  }, [csvData.length]);

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
            <div className="flex-row d-flex align-items-center">
              <h5 className="mb-0 medium text-nowrap">Users</h5>
              <div className="input-group ms-2 ms-md-4">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text border-0 h-100"
                    id="basic-addon1">
                    <i className="far fa-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control border-0 ps-0"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    pagination.goToPage(1);
                  }}
                />
              </div>
              <div className="input-group ms-2 ms-md-4">
                <select
                  style={{ border: "1px solid #ccc", borderRadius: 5 }}
                  className="form-select form-control form-control-sm"
                  onChange={(e) => setType(+e.target.value)}>
                  <option
                    value=""
                    disabled>
                    Types
                  </option>
                  <option
                    value={0}
                    selected>
                    All
                  </option>
                  <option value={1}>Customers</option>
                  <option value={2}>Stores</option>
                </select>
                <span className="line"></span>
              </div>
            </div>
            {/* <button
              type="submit"
              onClick={csvDataHandler}
              className="btn wizard-next-btn">
              Download
            </button> */}
            <CSVLink
              style={{ display: "none" }}
              data={csvData}
              filename={
                type === 1
                  ? "wnwn.csv"
                  : type === 2
                  ? "wnwn.csv"
                  : "wnwn.csv"
              }
              ref={csvLinkRef}>
              export
            </CSVLink>
            {/* {userType != 3 && (
                  <a
                    role="button"
                    onClick={() => setIsOpen(true)}
                    className="btn">
                    + Add New Users{" "}
                  </a>
                )} */}
          </div>
          <div className="card-body">
            <div className="table-responsive-xs text-nowrap mb-4">
              <table className="table table-borderless medium data-table-block">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th>Address</th> */}
                    {/* <th>Phone</th> */}
                    <th>Type</th>
                  </tr>
                </thead>

                <tbody className="table-border-bottom-0 text-1e">
                  {users?.data
                    ?.filter((user) =>
                      user?.name
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase()),
                    )
                    ?.filter((user) => (type ? user.type == type : user))
                    ?.map((data) => (
                      <tr key={data?.id}>
                        <td>
                          {/* <img
                              src={data?.image ? data?.image : organizationPic}
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            /> */}
                          {data?.name}
                        </td>
                        <td>{data?.email}</td>
                        <td className={`text-black`}>
                          {data?.type === 1
                            ? "Customer"
                            : data?.type === 2
                            ? "Store"
                            : data?.type === 3
                            ? "Admin"
                            : ""}
                        </td>
                        {/* <td>{`${data?.location}`}</td> */}
                        {/* <td>{data?.phone ? data?.phone : "-"}</td> */}
                        
                      </tr>
                    ))}
                </tbody>
              </table>
              {isLoading && !users?.data?.length && (
                <div className="loading-text">
                  <p>Loading...</p>
                </div>
              )}
            </div>

            {pagination?.totalPages > 1 && (
              <Pagination pagination={pagination} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
