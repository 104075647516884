import { getLocalData } from "../../utils/functions";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";

const initialState = {
  restaurants: [],
  meals: [],
  myOrders: [],
  orders: null,
  codeDocuments: null,
  organization: null,
  userInfo: null,
  ads: null,
  requests: null,
  scheduleCalls: null,
  subscribeTransactions: null,
  connectedBussiness: null,
  displayAds: null,
  transactions: null,
  users: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
