import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = ({ setDate, date, label, setDatePickerOpen, type }) => {
  const [openDrop, setIsOpenDrop] = useState(false);

  const isMonday = (date) => {
    return date.getDay() === 1; // Monday is represented by 1 in JavaScript's getDay() method
  };

  const isFirstDayOfMonth = (date) => {
    return date.getDate() === 1; // Check if the date is the 1st day of the month
  };

  const filterDates = (date) => {
    // Disable all dates except Mondays
    return type === "Weekly"
      ? isMonday(date)
      : type === "Monthly"
      ? isFirstDayOfMonth(date)
      : true;
  };

  return (
    <>
      <DatePicker
        onChange={(currentDate) => {
          setDate(currentDate);
        }}
        filterDate={filterDates}
        disabled={label === "Signup Date"}
        onSelect={() => {
          setIsOpenDrop(false);
          setDatePickerOpen && setDatePickerOpen(false);
        }}
        className="form-control"
        selected={new Date(date)?.getTime()}
        open={openDrop}
        showIcon
        onBlur={() => {
          setIsOpenDrop(false);
          setDatePickerOpen && setDatePickerOpen(false);
        }}
        onInputClick={() => {
          setIsOpenDrop(true);
          setDatePickerOpen && setDatePickerOpen(true);
        }}
        icon={
          <svg
            onClick={() => {
              setIsOpenDrop(true);
              setDatePickerOpen && setDatePickerOpen(true);
            }}
            style={{
              right: 0,
              fontSize: 18,
              cursor: "pointer",
            }}
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            fill="#333"
            viewBox="0 0 448 512">
            <path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V160h352v298c0 3.3-2.7 6-6 6z" />
          </svg>
        }
      />
      <span className="line"></span>
    </>
  );
};

export default DateInput;
