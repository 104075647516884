import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoices,
  getRestaurantsAdmin,
} from "../../store/actions/dashboardAction";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import { usePagination } from "../../apis/hooks";
import Pagination from "./Pagination";
import { deleteAds } from "../../store/actions/authAction";
import AddInvoice from "../../modals/add-invoice";
import moment from "moment";

const Invoices = ({ history }) => {
  const dispatch = useDispatch();
  const [showData, setShowData] = useState([]);
  const [pageType, setPageType] = useState("table");
  const [addNew, setAddNew] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { userInfo, invoices } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(getInvoices());
  }, []);

  const pagination = usePagination(invoices?.length, showDataPerPage);
  const userType = getLocalData("userType");

  useEffect(() => {
    if (invoices?.length && pagination.current > 0) {
      setShowData(
        invoices?.slice(
          (pagination.current - 1) * showDataPerPage,
          pagination.current * showDataPerPage,
        ),
      );
    }
  }, [invoices, pagination.current, searchText]);

  return (
    <>
      {pageType === "table" ? (
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
                <div className="flex-row d-flex align-items-center">
                  <h5 className="mb-0 medium text-nowrap">
                    Invoices{" "}
                    {(!isLoading || invoices?.length) &&
                      `(${invoices?.length || "0"})`}
                  </h5>
                  {/* <div className="input-group ms-2 ms-md-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                    />
                  </div> */}
                </div>

                {userType == 3 && (
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/add-invoice");
                    }}
                    className="btn">
                    + New{" "}
                  </a>
                )}
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        {userInfo?.type === 3 && <th>Store</th>}
                        {userInfo?.type === 2 && (
                          <>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </>
                        )}
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((invoice) => (
                        <tr key={invoice?.id}>
                          {userInfo?.type === 3 && (
                            <td>
                              {invoice?.storeName ? invoice?.storeName : "-"}
                            </td>
                          )}
                          {userInfo?.type === 2 && (
                            <>
                              <td>
                                {moment(invoice?.startDate).format(
                                  "MM/DD/YYYY",
                                )}
                              </td>
                              <td>
                                {moment(invoice?.endDate).format("MM/DD/YYYY")}
                              </td>
                            </>
                          )}
                          <td>${Number(invoice?.amount)?.toFixed(2)}</td>
                          <td>
                            {moment(invoice?.created_at).format("MM/DD/YYYY")}
                          </td>
                          <td
                            style={{
                              color:
                                invoice?.status === "Yes" ? "green" : "red",
                            }}>
                            {invoice?.status
                              ? invoice?.status === "Yes"
                                ? "Paid"
                                : "Pending"
                              : "-"}
                          </td>
                          <td>
                            <a
                              role="button"
                              onClick={() =>
                                history.push(
                                  `/wnwn/admin/invoice?id=${invoice?.id}`,
                                )
                              }>
                              View Details
                            </a>
                          </td>
                          {invoice?.status === "No" && userInfo?.type === 3 && (
                            <td>
                              <a
                                role="button"
                                onClick={() =>
                                  history.push(
                                    `/wnwn/admin/add-invoice?edit=${invoice?.id}`,
                                  )
                                }>
                                Edit
                              </a>
                            </td>
                          )}
                          {/* <td>
                            <a
                              target="_blank"
                              href={`/wnwn/invoice?id=${invoice?.id}&download=true`}>
                              Download
                            </a>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !invoices?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoading && !invoices?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={invoices}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <OrganizationProfile
        //   userInfo={userInfo}
        //   setUserInfo={setUserInfo}
        //   setPageType={setPageType}
        //   editProfile={editProfile}
        //   setEditProfile={setEditProfile}
        //   getDetails={getDetails}
        //   invoices={true}
        // />
        <div></div>
      )}
      <AddInvoice
        modalIsOpen={addNew}
        setIsOpen={setAddNew}
        closeModal={() => setAddNew(false)}
      />
    </>
  );
};

export default Invoices;
