import React, { useEffect, useState } from "react";
import TextField from "../../forms/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  addNewBag,
  getMealDetails,
  getMeals,
  updateBagDetails,
  uploadBagImage,
} from "../../store/actions/dashboardAction";
import { useDispatch } from "react-redux";
import { timeValues } from "../../utils/functions";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";

const userSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  description: yup.string().trim().required("Description is Required"),
  price: yup.number("Please add valid number").required("Price is Required"),
  quantity: yup.string().trim().required("Quantity is Required"),
  collection_from: yup.string().trim().required("Collection from is Required"),
  collection_to: yup.string().trim().required("Collection to is Required"),
});

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const bagTypes = ["Meal", "Groceries", "Surprise Bag"];

const AddNewBag = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [selectedDays, setSelectedDays] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);
  const [bagType, setBagType] = useState("meal");
  const [imgUrl, setImgUrl] = useState(null);
  const [img, setImg] = useState(null);
  const [error, setError] = useState("");
  const [pageType, setPageType] = useState("form");
  const [payload, setPayload] = useState(null);
  const [bagDetails, setBagDetails] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (window.location?.search?.includes("bag-id")) {
      const id = window.location.search.split("=")[1];
      getBagDetails(id);
    }
  }, []);

  const getBagDetails = async (id) => {
    const res = await dispatch(getMealDetails(id));
    if (res?.length) {
      const data = res[0];

      setBagDetails(data);
      setValue("name", data?.name);
      setValue("description", data?.description);
      setValue("price", data?.cost);
      setValue("quantity", data?.quantity);
      setValue("collection_from", data?.pickUpTime.split("-")?.[0]?.trim());
      setValue("collection_to", data?.pickUpTime.split("-")?.[1]?.trim());
      if (data?.image) {
        setImgUrl(data?.image?.split("/")[data?.image?.split("/")?.length - 1]);
        setImg(data?.image);
      }
      setBagType(data?.category);
      if (data?.days) setSelectedDays(data?.days?.split(","));
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      getBase64(e.target.files[0]);
      var data = new FormData();

      data.append("bagPhoto", e.target.files[0]);

      setIsLoadingImg(true);
      const res = await dispatch(uploadBagImage(data));

      if (res?.photo) {
        setImgUrl(res?.photo);
      }
      setIsLoadingImg(false);
    }
  };

  const addHandler = async (data) => {
    setError("");
    if (!selectedDays?.length) {
      return setError("Please Select at least one week day");
    }

    if (!imgUrl) {
      return setError("Please upload bag image");
    }

    const payloadLocal = {
      category: bagType,
      name: data?.name,
      description: data?.description,
      cost: data?.price,
      days: selectedDays.join(","),
      startTime: data?.collection_from,
      endTime: data?.collection_to,
      quantity: data?.quantity,
      bagPhoto: imgUrl,
    };

    setPayload(payloadLocal);
    setDetails({
      ...payloadLocal,
      image: img,
      pickUpTime: `${data?.collection_from} - ${data?.collection_to}`,
      selectedDays: selectedDays,
    });
    setIsOpen(true);
    // setPageType("review");
  };

  const daySelectHandler = (val) => {
    if (selectedDays.includes(val)) {
      setSelectedDays(selectedDays.filter((day) => day !== val));
    } else {
      setSelectedDays((prev) => [...prev, val]);
    }
  };

  const confirmHandler = async () => {
    setIsLoading(true);
    if (bagDetails?.id) {
      const updatePayload = {
        ...payload,
        id: bagDetails?.id,
      };
      await dispatch(updateBagDetails(updatePayload));
    } else {
      await dispatch(addNewBag(payload));
    }
    await dispatch(getMeals());
    setIsLoading(false);
    history.push("/wnwn/admin/bags");
  };

  return (
    <div className="content-wrapper container-home">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4 mt-4">
          <div className="row">
            <div
              className="col-md-5 text-start"
              style={{
                paddingTop: 20,
                paddingLeft: 40,
              }}>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/admin/bags");
                }}>
                <i className="far fa-arrow-left me-2"></i> Back To Listing
              </a>
            </div>
            <div
              className="col-md-7 mt-3 new-bag"
              style={{ textAlign: "center" }}>
              <h4 style={{ width: 100 }}>New Bag</h4>
            </div>
          </div>

          <div className="card-body col-md-12 add-new-bag">
            <form
              onSubmit={handleSubmit(addHandler)}
              className={`row g-4 mb-4 mb-md-5`}
              style={{ display: "flex", justifyContent: "center" }}>
              {pageType === "form" ? (
                <>
                  <div className="row col-md-8 mt-3">
                    <div
                      className="col-md-12"
                      style={{
                        position: "relative",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                      }}>
                      {img ? (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                            }}>
                            <label
                              style={{ cursor: "pointer" }}
                              disabled
                              for="file-upload"
                              tabindex="0">
                              <i
                                style={{ fontSize: 18, color: "#80CC27" }}
                                className="fas fa-pen"></i>
                            </label>
                            <input
                              disabled={isLoadingImg}
                              type="file"
                              id="file-upload"
                              accept="image/jpg, image/png, image/jpeg"
                              hidden="true"
                              onChange={uploadImage}
                            />
                          </div>
                          <img
                            src={img}
                            style={{ height: 200 }}
                          />
                        </>
                      ) : (
                        <>
                          <label
                            disabled
                            for="upload"
                            className="file-upload mb-0 image-ads-upload"
                            tabindex="0"
                            style={{
                              backgroundColor: "#eee",
                              color: "#80CC27",
                              width: 300,
                              height: 200,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <i class="fa fa-upload"></i>{" "}
                            {isLoadingImg ? "Uploading..." : "Upload Photo"}
                          </label>
                          <input
                            disabled={isLoadingImg}
                            type="file"
                            id="upload"
                            accept="image/jpg, image/png, image/jpeg"
                            hidden="true"
                            onChange={uploadImage}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row col-md-8 mt-3">
                    <label className="mb-2">Select Bag type</label>
                    {bagTypes?.map((type) => (
                      <div className="col-md-4">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            name="type"
                            type="radio"
                            value={type}
                            checked={type == bagType}
                            id={type}
                            onChange={() => setBagType(type)}
                          />
                          <label
                            className="form-check-label"
                            for={type}>
                            {type}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row col-md-8 mt-4">
                    <div className="col-md-6 mt-3">
                      <label className="mb-2">Enter Name</label>
                      <TextField
                        errors={errors}
                        placeholder="Enter Name"
                        register={register}
                        fieldName="name"
                        inputClass="form-control edit-form-control"
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="mb-2">Enter Description</label>
                      <TextField
                        errors={errors}
                        placeholder="Enter Description"
                        register={register}
                        fieldName="description"
                        inputClass="form-control edit-form-control"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label className="mb-2">Set Price</label>
                      <TextField
                        errors={errors}
                        placeholder="Enter Price (USD)"
                        register={register}
                        fieldName="price"
                        inputClass="form-control edit-form-control"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label className="mb-2">Enter Daily Quantity</label>
                      <TextField
                        errors={errors}
                        placeholder="Enter Daily Quantity"
                        register={register}
                        fieldName="quantity"
                        inputClass="form-control edit-form-control"
                      />
                    </div>
                  </div>
                  <div className="row col-md-8 mt-3">
                    <label className="mb-2">Days of week</label>
                    {daysOfWeek?.map((val) => (
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => daySelectHandler(val)}
                            value={val}
                            checked={selectedDays?.includes(val)}
                            id={val}
                          />
                          <label
                            className="form-check-label"
                            for={val}>
                            {val}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row col-md-8 mt-3">
                    <label className="mb-2">Collection Time</label>
                    <div className="col-md-6">
                      <div className="style-line">
                        <select
                          className="form-select form-control form-control-sm"
                          onChange={(e) =>
                            setValue("collection_from", e.target.value)
                          }>
                          <option
                            value=""
                            disabled
                            selected>
                            From
                          </option>
                          {timeValues?.map((val) => (
                            <option
                              selected={val == getValues("collection_from")}
                              value={val}
                              key={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="style-line">
                        <select
                          className="form-select form-control form-control-sm"
                          onChange={(e) =>
                            setValue("collection_to", e.target.value)
                          }>
                          <option
                            value=""
                            disabled
                            selected>
                            To
                          </option>
                          {timeValues?.map((val) => (
                            <option
                              selected={val == getValues("collection_to")}
                              value={val}
                              key={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row col-md-8 mt-2"
                    style={{ marginTop: -40 }}>
                    <div className="col-md-6">
                      <button
                        onClick={() =>
                          history.push("/wnwn/admin/bags")
                        }
                        disabled={isLoading}
                        className="btn w-100 mt-md-5 wizard-next-btn grey-btn">
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn w-100 mt-md-5 wizard-next-btn">
                        {bagDetails?.id ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "center",
                    }}>
                    {error ? error : ""}
                  </p>
                </>
              ) : (
                <>
                  <div className="col-md-8 row">
                    <h5>Review your selection</h5>
                    <img
                      src={img}
                      style={{ width: "100%" }}
                    />
                    <div className="col-md-6 mt-2">
                      <span className="desc-cat">Category</span>
                      <span>{bagType}</span>
                    </div>
                    <div className="col-md-6 mt-2">
                      <span className="desc-cat">Name</span>
                      <span>{getValues("name")}</span>
                    </div>
                    <div className="col-md-12 mt-2">
                      <span className="desc-cat">Description</span>
                      <span>{getValues("name")}</span>
                    </div>
                    <div className="col-md-12 mt-2">
                      <span className="desc-cat">Days of week</span>
                      {selectedDays?.map((day) => (
                        <span>{day} | </span>
                      ))}
                    </div>
                    <div className="col-md-12 mt-2">
                      <span className="desc-cat">Collection Time</span>
                      <span>
                        {getValues("collection_from")} -{" "}
                        {getValues("collection_to")}
                      </span>
                    </div>
                    <div className="col-md-6 mt-2">
                      <span className="desc-cat">Earnings & Fees</span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <span>Price</span>
                        <span>${Number(getValues("price"))?.toFixed(2)}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <span>Fee Per Bag Sold</span>
                        <span>
                          ${Number((getValues("price") / 100) * 6)?.toFixed(2)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <span>Your Earnings</span>
                        <span>
                          $
                          {(
                            Number(getValues("price")) +
                            Number((getValues("price") / 100) * 6)
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row col-md-8 mt-2"
                    style={{ marginTop: -40 }}>
                    <div className="col-md-6">
                      <button
                        onClick={() => setPageType("form")}
                        disabled={isLoading}
                        className="btn w-100 mt-5 wizard-next-btn grey-btn">
                        Edit
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={confirmHandler}
                        disabled={isLoading}
                        className="btn w-100 mt-5 wizard-next-btn">
                        {isLoading ? "Saving" : "Confirm"}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <BussinessDetailsModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        details={details}
        closeModal={() => setIsOpen(false)}
        history={history}
        confirmHandler={confirmHandler}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddNewBag;
