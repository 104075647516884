import React, { useState } from "react";
import Modal from "react-modal";
import AdminChangePasswordForm from "./admin-change-password-form";
import { useDispatch } from "react-redux";
import { getAds, getUsers } from "../store/actions/dashboardAction";
import { deleteAds, deleteUserAction } from "../store/actions/authAction";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    zIndex: 100000,
  },
};

const DeleteUserModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  isLoading,
  deleteHandler,
  logout,
  confirmPickup = false,
  confirmCancel = false,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -40,
            marginLeft: 20,
            marginRight: 20,
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div className="authentication-wrapper delete-modal">
          <div className="form-wizard">
            <h5 style={{ textAlign: "center", fontWeight: "600" }}>
              {logout
                ? "Are you sure you want to Logout?"
                : `Confirm ${
                    confirmPickup
                      ? "Pickup"
                      : confirmCancel
                      ? "Cancel Order"
                      : "Delete"
                  }?`}
            </h5>
            <div
              className="row"
              style={{ width: "300px", marginTop: "-30px" }}>
              <div className="col-md-6">
                <button
                  onClick={closeModal}
                  disabled={isLoading}
                  className="btn w-100 mt-md-5 wizard-next-btn grey-btn">
                  {logout ? "No" : "Cancel"}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  onClick={deleteHandler}
                  disabled={isLoading}
                  className="btn w-100 mt-md-5 wizard-next-btn">
                  {isLoading
                    ? "Processing"
                    : logout
                    ? "Yes"
                    : confirmPickup || confirmCancel
                    ? "Confirm"
                    : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteUserModal;
