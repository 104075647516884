import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FourOFour from "./views/404";
import Login from "./views/auth/Login";
import Dashboard from "./views/dashboard";
import Signup from "./views/auth/Signup";
import ForgotPassword from "./views/auth/forgot-password";
import Home from "./views/home";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/wnwn/admin"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/login"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/forgot-password"
          render={({ history }) => <ForgotPassword history={history} />}
          exact
        />
        {/* <Route
          path="/wnwn/admin/register"
          render={({ history }) => <Signup history={history} />}
          exact
        /> */}
        <Route
          path="/wnwn/admin"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/meals"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/bags"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/users"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/add-bag"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/add-invoice"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/invoice"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/profile"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/orders"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/transactions"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/invoices"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/stores"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/subscriptions"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/members"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/documents"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/quality-assurance"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/ads"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/statistics"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/requests"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/transactions"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/wnwn/admin/completed-calls"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/"
          render={({ history }) => <Home history={history} />}
          exact
        />
        <Route render={({ history }) => <FourOFour history={history} />} />
      </Switch>
    </Router>
  );
};

export default App;
