import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/home-style.css";
import logoImg from "../../assets/home-img/logo.png";
import transparentLogoImg from "../../assets/home-img/WnWn-logo-transparent.png";
import buttonApp from "../../assets/home-img/button-app.png";
import buttonPlay from "../../assets/home-img/button-play.png";
import img1 from "../../assets/home-img/img1.png";
import img2 from "../../assets/home-img/img2.png";
import img3 from "../../assets/home-img/img3.png";
import oval from "../../assets/home-img/Oval.png";
import wave from "../../assets/home-img/Wave.svg";
import food from "../../assets/home-img/ep_food.png";
import icon from "../../assets/home-img/Icon.png";
import icon1 from "../../assets/home-img/Icon-1.png";
import icon2 from "../../assets/home-img/Icon-2.png";
import icon3 from "../../assets/home-img/Icon-3.png";
import AppleQR from "../../assets/home-img/apple-store.jpeg";
import AndroidQR from "../../assets/home-img/play-store.png";
import Insta from "../../assets/home-img/insta.png";
import TikTok from "../../assets/home-img/ticktok.png";
import X from "../../assets/home-img/x.webp";
import FB from "../../assets/home-img/fb.png";
import HomeSidebar from "./home-sidebar";

const Home = ({ history }) => {
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [view, setView] = useState("home");
  const [img, setImg] = useState(img1);
  const [index, setIndex] = useState(2);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (window.location.hash.includes("#about-us")) {
      scrollToSection(featuresRef);
      history.push("/");
    }
  }, [window.location.hash]);

  useEffect(() => {
    setTimeout(() => {
      setIndex(index == 3 ? 1 : index + 1);
      setImg(index == 1 ? img1 : index == 2 ? img2 : img3);
    }, 3000);
  }, [img, index]);

  useEffect(() => {
    setShowSidebar(false);
  }, [window.location.href]);

  return (
    <div>
      <HomeSidebar
        showSidebar={showSidebar}
        heroRef={heroRef}
        featuresRef={featuresRef}
        scrollToSection={scrollToSection}
        setShowSidebar={setShowSidebar}
        setView={setView}
      />
      <header className="header">
        <div className="logo">
          <a
            role="button"
            onClick={() => setView("home")}>
            <img
              src={logoImg}
              alt="Waste not Want not Logo"
            />
          </a>
        </div>
        <nav className="nav">
          <ul>
            <li>
              <a
                role="button"
                onClick={() => {
                  scrollToSection(heroRef);
                  setView("home");
                }}>
                Home
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => {
                  scrollToSection(featuresRef);
                }}>
                About us
              </a>
            </li>
            {/* <li>
              <a href="#">Help</a>
            </li> */}
            <li>
              <a
                href="https://apps.apple.com/us/app/wnwn/id6673886035"
                target="_blank"
                className="btn">
                Get it now
              </a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger"
          onClick={() => setShowSidebar(!showSidebar)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>

      <section
        ref={heroRef}
        style={{ backgroundColor: "", overflow: "hidden" }}
        className="hero">
        <div
          className="hero-content"
          style={{ backgroundColor: "" }}>
          <img
            src={transparentLogoImg}
            className="hero-logo"
          />
          {view === "home" ? (
            <>
              <h1>Waste Not Want Not</h1>
              <span style={{ fontWeight: 600, fontSize: 16 }}>
                At Waste Not Want Not our daily mission is to help bring savings
                to the pocketbooks of people while helping businesses with daily
                expenditures.
                <br />
                <br />
                We are a for The People Company who really enjoy happy smiling
                customers and businesses. We want to make a difference in as
                many lives as possible with a cost savings at local businesses.
                <br />
                <br />
                When you search our app you will see participating businesses,
                maybe ones haven’t been to yet or those you frequent often.
                <br />
                <br />
                We would love to give you an opportunity to try various products
                or the ones you already enjoy at a reduced cost while helping
                that business with their extras at end of the day.
                <br />
                <br />
                We want to see people Win as a collective, this is a Win for you
                and a Win for that business!
              </span>
            </>
          ) : view === "less-price" ? (
            <>
              <h1>Meals at lower prices</h1>
              <span style={{ fontWeight: 600, fontSize: 16 }}>
                Our initiative focuses on rescuing food that would otherwise go
                to waste by offering it to consumers at discounted rates. Meals
                are sold at 50-70% of their contents' original retail value.
                <br />
                <br />
                Our app allows restaurants, bakeries, and stores to sell unsold
                food at the end of the day at a discounted price. You can
                purchase a “surprise bag” with food that would otherwise go to
                waste.
              </span>
            </>
          ) : view === "rescue" ? (
            <>
              <h1>Rescue food near you</h1>
              <span style={{ fontWeight: 600, fontSize: 16 }}>
                Reducing food waste is a great way to save money and contribute
                to a more sustainable lifestyle. We help people rescue food from
                going to waste, offering great value for money.
                <br />
                <br />
                By purchasing meals from our app, you'll cut down on food waste,
                save money, and help reduce your environmental footprint.
              </span>
            </>
          ) : (
            <>
              <h1>Help The Environment</h1>
              <span style={{ fontWeight: 600, fontSize: 16 }}>
                Food waste is a pressing global issue that not only affects food
                security but also significantly impacts the environment. In a
                world where millions go hungry, approximately one-third of all
                food produced for human consumption is wasted, according to the
                Food and Agriculture Organization (FAO).
                <br />
                <br />
                At Waste Not Want Not we are working on emphasizing the
                importance of reducing food waste to foster a sustainable
                future.
                <br />
                <br />
                Reducing food waste is a critical component of environmental
                sustainability. By addressing the causes and consequences of
                food waste, we can significantly decrease greenhouse gas
                emissions, conserve natural resources, and promote healthier
                ecosystems.
                <br />
                <br />
                As a company, and as individuals, we must commit to making
                conscious choices to protect our planet for future generations.
                <br />
                <br />
                By valuing food and reducing waste, we can take meaningful steps
                toward a more sustainable and equitable world.
              </span>
            </>
          )}

          {/* <img
            src={transparentLogoImg}
            className="hero-logo"
          /> */}
        </div>
        <div
          className="phone-mockup"
          style={{ zIndex: 11, backgroundColor: "" }}>
          <img
            src={img}
            alt="App on Phone Mockup"
          />
        </div>
        <div className="oval-bg">
          <img
            src={oval}
            alt="oval bg"
          />
        </div>
        <img
          src={transparentLogoImg}
          className="hero-wave-logo"
        />
        <div className="wave-svg">
          <img
            style={{ width: "100vw" }}
            src={wave}
            alt="oval bg"
          />
        </div>

        <div className="qr-container">
          <div
            className="store-buttons"
            style={{ backgroundColor: "" }}>
            <div style={{ backgroundColor: "" }}>
              <a
                href="https://apps.apple.com/us/app/wnwn/id6673886035"
                target="_blank">
                <div className="buttons-container">
                  <img
                    src={buttonApp}
                    alt="Download on the App Store"
                  />

                  <img
                    src={AppleQR}
                    alt="Apple QR"
                    className="qr-code"
                  />
                </div>
              </a>
            </div>
            <br />
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.food.wastenotwantnot"
                target="_blank">
                <div className="buttons-container">
                  <img
                    src={buttonPlay}
                    alt="Get it on Google Play"
                  />

                  <img
                    src={AndroidQR}
                    alt="Android QR"
                    className="qr-code"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={featuresRef}
        className="features">
        <img
          src={transparentLogoImg}
          className="features-logo"
        />
        <h2 style={{ marginTop: 30 }}>Why use Waste not Want Not</h2>
        <div className="features-grid">
          <div className="feature-item">
            <img
              src={food}
              alt="Icon 3"
            />
            <h3>Enjoy good food at 1/3 price or less</h3>
            <p>
              Surprise Bags are sold at 50-70% of their contents' original
              retail value.
            </p>
            <a
              role="button"
              onClick={() => {
                setView("less-price");
                setTimeout(() => window.scrollTo(0, 0), 100);
              }}>
              Learn more
            </a>
          </div>
          <div className="feature-item">
            <i className="admin-icon tf-icons fas fa-pizza"></i>
            <h3>Rescue food near you</h3>
            <p>
              We help people rescue food from going to waste, offering great
              value for money.
            </p>
            <a
              role="button"
              onClick={() => {
                setView("rescue");
                setTimeout(() => window.scrollTo(0, 0), 100);
              }}>
              Learn more
            </a>
          </div>
          <div className="feature-item">
            <i className="admin-icon tf-icons fas fa-tree"></i>
            <h3>Help the environment</h3>
            <p>
              Minimize environmental impacts associated with the disposal of
              waste in landfills.
            </p>
            <a
              role="button"
              onClick={() => {
                setView("environment");
                setTimeout(() => window.scrollTo(0, 0), 100);
              }}>
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section className="connect">
        <h2>Connect with us</h2>
        <div className="connect-container">
          <a
            target="_blank"
            href="https://www.instagram.com/waste.not.want.not.today">
            <img src={Insta} />
          </a>
          <a
            target="_blank"
            href="https://www.tiktok.com/@waste.not.want.not">
            <img style={{borderRadius:10}} src={TikTok} />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61566959973668">
            <img
              src={FB}
              className="fb-img"
            />
          </a>
          <a
            target="_blank"
            href="https://x.com/WastenWantn">
            <img
              style={{ borderRadius: 10 }}
              src={X}
            />
          </a>
          
        </div>
      </section>

      <section className="call-to-action">
        <h2>Try our awesome app now</h2>
        <p>
          No contact, no set-up costs, just awesome way to distribute your food.
        </p>
        <div className="cta-buttons">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/wnwn/id6673886035"
            className="btn primary">
            Try it for free
          </a>
          {/* <a
            href="#"
            className="btn secondary">
            Watch Demo
          </a> */}
        </div>
      </section>

      <footer className="footer">
        <div className="footer-logo">
          <img
            src={logoImg}
            alt="Footer Logo"
          />
          <p>Empower everyone to fight food waste together.</p>
          {/* <p style={{ fontWeight: "bold" }}>
            Contact us: <br />
            Email - info@wastenwantn.com
          </p> */}
        </div>
        <p>Waste Not Want Not &copy; 2024. All rights reserved.</p>
        <div className="social-links">
          <a href="#">
            {/* <img
              src={icon}
              alt="Google Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon1}
              alt="Twitter Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon2}
              alt="Instagram Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon3}
              alt="LinkedIn Icon"
            /> */}
          </a>
          <p>
            Contact us: <br />
            Email - info@wastenwantn.com
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
