import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import BussinessDetails from "./bussiness-details";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    zIndex: 100000,
    // backgroundColor: "#1E2A42",
  },
};

const BussinessDetailsModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  details,
  history,
  confirmHandler,
  isLoading,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -40,
            marginLeft: 20,
            marginRight: 20,
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <BussinessDetails
          closeModal={closeModal}
          details={details}
          history={history}
          confirmHandler={confirmHandler}
          isLoading={isLoading}
        />
      </Modal>
    </div>
  );
};

export default BussinessDetailsModal;
