import React, { useEffect, useState } from "react";
import defaultPic from "../../assets/img/profile-default.png";
import { getLocalData } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/actions/dashboardAction";

const Header = ({ history, showSidebar, setShowSidebar }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
      style={{ padding: 0 }}>
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a
          className="nav-item nav-link p-0 me-xl-4"
          role="button"
          onClick={() => setShowSidebar(true)}>
          <i className="fal fa-bars fa-2x"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center justify-content-between"
        id="navbar-collapse">
        <div className="col-md-4 col-6 d-block d-xl-none">
          <div className="">
            <a
              role="button"
              onClick={() => history.push("/wnwn/")}
              className="app-brand-link h2 mb-0 primary-color bold">
              <em>Waste Not</em>
            </a>
          </div>
        </div>
        {/* <h5
          className="col-md-4 col-6 semi user-name-first mb-0"
          style={{ backgroundColor: "" }}>
          <span style={{ color: "#333", lineHeight: 1.5 }}>
            Hi {getLocalData("userName")},
          </span>{" "}
          <br />
          Welcome Back!
        </h5> */}
      </div>
    </nav>
  );
};

export default Header;
