import React, { useEffect, useState } from "react";
import LoginImage from "../../assets/images/login.jpg";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../../forms/TextField";
import { inputValidator, stateOptions } from "../../utils/functions";
import {
  checkEmail,
  getOrganizationOptions,
  registerUser,
} from "../../store/actions/authAction";
import { setUIKey } from "../../store/actions/uiAction";
import moment from "moment";

const userSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  phone: yup.string().trim().required("Phone is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
});

const organizationSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  phone: yup.string().trim().required("Phone is Required"),
  address: yup.string().trim().required("Address is Required"),
  city: yup.string().trim().required("City is Required"),
  state: yup.string().trim().required("State is Required"),
  zip: yup
    .string()
    .trim()
    .required("Zip is Required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip"),
  adminName: yup.string().trim().required("Contact Name is Required"),
  category: yup.string().trim().required("Category is Required"),
  // website: yup.string().trim().required("Website is Required"),
  description: yup.string().trim().required("Description is Required"),
});

const schema = yup.object({
  name: yup.string().trim().required("Username is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  phone: yup.string().trim().required("Phone is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
  address: yup.string().trim().required("Address is Required"),
  city: yup.string().trim().required("City is Required"),
  state: yup.string().trim().required("State is Required"),
  zip: yup
    .string()
    .trim()
    .required("Zip is Required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip"),
  adminName: yup.string().trim().required("Contact Name is Required"),
  category: yup.string().trim().required("Category is Required"),
  // website: yup.string().trim().required("Website is Required"),
  description: yup.string().trim().required("Description is Required"),
});

const Signup = ({ history }) => {
  const [formType, setFormType] = useState("user");
  const [userType, SetUserType] = useState(4);
  const [passwordType, setPasswordType] = useState("password");
  const [interests, setInterests] = useState([]);

  const { isLoading, error } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const {
    register,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      userType === 4 ? userSchema : userType == 2 ? organizationSchema : schema,
    ),
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = getValues();

    if (interests?.length < 3) {
      return dispatch(setUIKey("error", "Please Select 3 or more"));
    }

    dispatch(setUIKey("error", ""));

    const payload = {
      ...data,
      category: userType === 4 ? "" : data?.category,
      memberSince:
        userType === 2 ? "" : moment(new Date()).format("YYYY-MM-DD"),
      orgId: userType === 2 ? 0 : data?.orgId,
      interests: interests.join(","),
      facebook: "",
      instagram: "",
      twitter: "",
      type: userType,
    };

    const res = await dispatch(registerUser(payload));
    if (res.status === "success") {
      res.type === 4
        ? history.push("/wnwn/admin/profile")
        : history.push("/wnwn/admin/admin");
    }
  };

  const setUserInterests = (val) => {
    if (interests.includes(val)) {
      setInterests(interests.filter((int) => int !== val));
    } else {
      setInterests((oldVal) => [...oldVal, val]);
    }
  };

  const userNextHadler = async (e) => {
    e.preventDefault();

    const result = await trigger([
      "name",
      "email",
      "password",
      "phone",
      "orgId",
      "memberSince",
    ]);

    if (result) {
      const res = await dispatch(checkEmail({ email: getValues("email") }));

      if (res?.status === "success") {
        userType === 4 ? setFormType("interests") : setFormType("business");
        dispatch(setUIKey("error", ""));
      } else {
        dispatch(setUIKey("error", "Email already exists"));
      }
    }
  };

  const businessDetailsNext = async (e) => {
    e.preventDefault();

    const result = await trigger([
      "address",
      "city",
      "state",
      "zip",
      "orgId",
      "memberSince",
      "adminName",
      "category",
      "website",
      "description",
    ]);

    if (result) {
      setFormType("interests");
    }
  };

  const goBackHandler = () => {
    if (userType === 4) {
      setFormType("user");
    } else {
      setFormType("business");
    }
    dispatch(setUIKey("error", ""));
  };

  return (
    <div className="authentication-wrapper authentication-cover medium">
      <div className="authentication-inner row g-0 login-page">
        <div
          className={`col-md-12 form-wizard  ${
            formType === "interests" ? "interest-div" : "signup-div"
          }`}>
          <form className="position-relative">
            <div
              className={`wizard-fieldset  d-flex authentication-bg p-sm-5 p-4 login-ss ${
                formType === "user" ? "show" : ""
              } `}>
              <div
                className="w-px-400 mx-auto"
                style={{ marginTop: -20 }}>
                <h2 className="mb-2 semi">Create an account</h2>
                <p className="mb-4 ">
                  Already have an account?{" "}
                  <a
                    role="button"
                    onClick={() => history.push("/wnwn/admin/login")}>
                    Sign in
                  </a>
                </p>

                <div className="form-block">
                  <label className="text-muted small mb-2">Sign up as</label>
                  <div className="pp-type d-flex align-items-center flex-wrap mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="pp-type"
                        type="radio"
                        value=""
                        id="Member"
                        checked={userType === 4}
                      />
                      <label
                        className="form-check-label"
                        for="Member"
                        onClick={() => SetUserType(4)}>
                        Member
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="pp-type"
                        type="radio"
                        value=""
                        id="bb"
                        checked={userType === 3}
                      />
                      <label
                        className="form-check-label"
                        for="bb"
                        onClick={() => SetUserType(3)}>
                        Business
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="pp-type"
                        type="radio"
                        value=""
                        id="Organization"
                        checked={userType === 2}
                      />
                      <label
                        className="form-check-label"
                        for="Organization"
                        onClick={() => SetUserType(2)}>
                        Organization
                      </label>
                    </div>
                  </div>
                  <TextField
                    errors={errors}
                    placeholder="Name"
                    register={register}
                    fieldName="name"
                  />
                  <TextField
                    errors={errors}
                    placeholder="Email"
                    register={register}
                    fieldName="email"
                  />

                  <TextField
                    inputType={passwordType}
                    errors={errors}
                    divClass="style-line mb-3 form-password-toggle"
                    placeholder="Password"
                    register={register}
                    fieldName="password"
                    icon={<i className="far fa-eye-slash"></i>}
                    viewPassword={() =>
                      setPasswordType(
                        passwordType === "password" ? "text" : "password",
                      )
                    }
                  />

                  <TextField
                    errors={errors}
                    placeholder="Phone"
                    register={register}
                    fieldName="phone"
                  />
                  {userType !== 2 && (
                    <>
                      <div className="style-line">
                        <select
                          className="form-select form-control form-control-sm"
                          onChange={(e) => setValue("orgId", +e.target.value)}>
                          <option
                            value=""
                            disabled
                            selected>
                            Organization
                          </option>
                          {organizationOptions?.map((val) => (
                            <option
                              value={val?.id}
                              key={val?.id}>
                              {val?.name}
                            </option>
                          ))}
                        </select>
                        <span className="line"></span>
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "2px",
                          }}>
                          {errors?.["orgId"]?.message}
                        </p>
                        {/* <TextField
                            errors={errors}
                            placeholder="Organization"
                            register={register}
                            fieldName="orgId"
                            icon={<i className="far fa-search"></i>}
                          /> */}
                      </div>
                      {/* <div className="col-md-6">
                          <TextField
                            errors={errors}
                            placeholder="Member Since"
                            register={register}
                            inputType="date"
                            fieldName="memberSince"
                          />
                        </div> */}
                    </>
                  )}

                  <button
                    onClick={userNextHadler}
                    disabled={isLoading}
                    className="btn w-100 mb-3 wizard-next-btn">
                    {isLoading ? "Processing" : "Next"}
                  </button>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 5,
                    }}>
                    {error && !isLoading ? error : ""}
                  </p>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="consent"
                        checked
                      />
                      <label
                        className="form-check-label"
                        for="consent">
                        I consent to my information being shared with other
                        members
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`wizard-fieldset d-flex authentication-bg p-sm-5 p-4 login-ss ${
                formType === "business" ? "show" : ""
              }`}
              style={{ marginTop: -30 }}>
              <div className="w-px-400 mx-auto">
                <h2 className="mb-4 semi">
                  {userType === 3 ? "Business Details" : "Organization Details"}
                </h2>

                <div className="form-block">
                  <div className="row mb-md-4">
                    <div className="col-md-6">
                      <TextField
                        errors={errors}
                        placeholder="Address"
                        register={register}
                        fieldName="address"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        errors={errors}
                        placeholder="City"
                        register={register}
                        fieldName="city"
                      />
                    </div>
                    {/* <TextField
                        errors={errors}
                        placeholder="State"
                        register={register}
                        fieldName="state"
                      /> */}
                    <div className="col-md-6 style-line">
                      <select
                        className="form-select form-control form-control-sm"
                        onChange={(e) => setValue("state", e.target.value)}>
                        <option
                          value=""
                          disabled
                          selected>
                          State
                        </option>
                        {stateOptions?.map((val) => (
                          <option value={val.name}>{val.name}</option>
                        ))}
                      </select>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["state"]?.message}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <TextField
                        errors={errors}
                        placeholder="Zip"
                        register={register}
                        fieldName="zip"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        errors={errors}
                        placeholder="Contact Name"
                        register={register}
                        fieldName="adminName"
                      />
                    </div>
                    <div className="col-md-6 style-line">
                      <select
                        className="form-select form-control form-control-sm"
                        onChange={(e) => setValue("category", e.target.value)}>
                        <option
                          value=""
                          disabled
                          selected>
                          Category
                        </option>
                        {userType === 3 ? (
                          <>
                            <option value="Coffee Shop">Coffee Shop</option>
                            <option value="Restaurant">Restaurant</option>
                            <option value="Car Wash">Car Wash</option>
                            <option value="Taxi Service">Taxi Service</option>
                          </>
                        ) : (
                          <>
                            <option value="Golf Course">Golf Course</option>
                            <option value="Church">Church</option>
                          </>
                        )}
                      </select>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["category"]?.message}
                      </p>
                    </div>
                    {/* <div className="col-md-12">
                      <TextField
                        errors={errors}
                        placeholder="Website"
                        register={register}
                        fieldName="website"
                      />
                    </div> */}
                    <div className="col-md-12 mt-4">
                      <div className="style-line mb-3">
                        <textarea
                          style={{
                            border: "solid 1px lightgrey",
                            borderRadius: 10,
                            padding: 10,
                          }}
                          {...register(`description`)}
                          className="form-control h-auto"
                          rows="8"
                          placeholder="Provide some information regarding your business"></textarea>
                        <span className="line"></span>
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "2px",
                          }}>
                          {errors?.["description"]?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <a
                    role="button"
                    onClick={businessDetailsNext}
                    className="btn w-100 mb-3 wizard-next-btn">
                    Next
                  </a>
                  <div className="mb-3">
                    <div className="text-center">
                      <a
                        role="button"
                        onClick={() => setFormType("user")}
                        className="wizard-previous-btn">
                        Go Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`wizard-fieldset d-flex align-items-center authentication-bg p-sm-5 p-4 login-ss ${
                formType === "interests" ? "show" : ""
              }`}>
              <div className="w-px-400 mx-auto">
                <h2
                  className="mb-2 semi"
                  style={{ marginTop: -180 }}>
                  What are your interests?
                </h2>
                <p className="mb-4 ">Select 3 or more </p>

                <div className="form-block">
                  <div className="pp-type d-flex align-items-center flex-wrap mb-4">
                    <div
                      className="form-check"
                      for="Art">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        checked={interests.includes("art")}
                        id="Art"
                      />
                      <label
                        className="form-check-label"
                        onClick={() => setUserInterests("art")}>
                        Art
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="travel"
                        type="checkbox"
                        value="travel"
                        checked={interests.includes("travel")}
                        id="Travel"
                      />
                      <label
                        className="form-check-label"
                        for="Travel"
                        onClick={() => setUserInterests("travel")}>
                        Travel
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Comics"
                        checked={interests.includes("comics")}
                      />
                      <label
                        className="form-check-label"
                        for="Comics"
                        onClick={() => setUserInterests("comics")}>
                        Comics
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Fiction"
                        checked={interests.includes("fiction")}
                      />
                      <label
                        className="form-check-label"
                        for="Fiction"
                        onClick={() => setUserInterests("fiction")}>
                        Fiction
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Fashion"
                        checked={interests.includes("fashion")}
                      />
                      <label
                        className="form-check-label"
                        for="Fashion"
                        onClick={() => setUserInterests("fashion")}>
                        Fashion
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Business"
                        checked={interests.includes("business")}
                      />
                      <label
                        className="form-check-label"
                        for="Business"
                        onClick={() => setUserInterests("business")}>
                        Business
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Sports"
                        checked={interests.includes("sports")}
                      />
                      <label
                        className="form-check-label"
                        for="Sports"
                        onClick={() => setUserInterests("sports")}>
                        Sports
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Movies"
                        checked={interests.includes("movies")}
                      />
                      <label
                        className="form-check-label"
                        for="Movies"
                        onClick={() => setUserInterests("movies")}>
                        Movies
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Writing"
                        checked={interests.includes("writing")}
                      />
                      <label
                        className="form-check-label"
                        for="Writing"
                        onClick={() => setUserInterests("writing")}>
                        Writing
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Cars"
                        checked={interests.includes("cars")}
                      />
                      <label
                        className="form-check-label"
                        for="Cars"
                        onClick={() => setUserInterests("cars")}>
                        Cars
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Books"
                        checked={interests.includes("books")}
                      />
                      <label
                        className="form-check-label"
                        for="Books"
                        onClick={() => setUserInterests("books")}>
                        Books
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Finance"
                        checked={interests.includes("finance")}
                      />
                      <label
                        className="form-check-label"
                        for="Finance"
                        onClick={() => setUserInterests("finance")}>
                        Finance
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Space"
                        checked={interests.includes("space")}
                      />
                      <label
                        className="form-check-label"
                        for="Space"
                        onClick={() => setUserInterests("space")}>
                        Space
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Baseball"
                        checked={interests.includes("baseball")}
                      />
                      <label
                        className="form-check-label"
                        for="Baseball"
                        onClick={() => setUserInterests("baseball")}>
                        Baseball
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="NBA"
                        checked={interests.includes("nba")}
                      />
                      <label
                        className="form-check-label"
                        for="NBA"
                        onClick={() => setUserInterests("nba")}>
                        NBA
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Netflix"
                        checked={interests.includes("netflix")}
                      />
                      <label
                        className="form-check-label"
                        for="Netflix"
                        onClick={() => setUserInterests("netflix")}>
                        Netflix
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Cooking"
                        checked={interests.includes("cooking")}
                      />
                      <label
                        className="form-check-label"
                        for="Cooking"
                        onClick={() => setUserInterests("cooking")}>
                        Cooking
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="Wines"
                        checked={interests.includes("wines")}
                      />
                      <label
                        className="form-check-label"
                        for="Wines"
                        onClick={() => setUserInterests("wines")}>
                        Wines
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="NFL"
                        checked={interests.includes("nfl")}
                      />
                      <label
                        className="form-check-label"
                        for="NFL"
                        onClick={() => setUserInterests("nfl")}>
                        NFL
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="interests"
                        type="checkbox"
                        value=""
                        id="History"
                        checked={interests.includes("history")}
                      />
                      <label
                        className="form-check-label"
                        for="History"
                        onClick={() => setUserInterests("history")}>
                        History
                      </label>
                    </div>
                  </div>

                  <button
                    disabled={isLoading}
                    onClick={onSubmit}
                    className="btn w-100 mb-3 wizard-next-btn">
                    {isLoading ? "Processing" : "Finish Sign Up"}
                  </button>
                  <div className="mb-3">
                    <div className="text-center">
                      <a
                        role="button"
                        onClick={goBackHandler}
                        className="wizard-previous-btn">
                        Go Back
                      </a>
                    </div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                        marginTop: 20,
                      }}>
                      {error ? error : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
