import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../store/actions/dashboardAction";
import moment from "moment";
import PDFImage from "../../assets/images/pdf.png";
import generatePDF from "react-to-pdf";

const InvoiceDetails = ({ history }) => {
  const [invoiceData, setInvoiceData] = useState(null);

  const targetRef = useRef();

  const dispatch = useDispatch();

  const { userInfo, invoices } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (window.location?.search?.includes("id") && invoices?.length) {
      const id = window.location.search.split("=")[1]?.replace("&download", "");

      setInvoiceData(invoices?.find((invoice) => invoice?.id == id));
    }
  }, [invoices]);

  useEffect(() => {
    if (
      window?.location?.search?.includes("&download=true") &&
      invoiceData?.id
    ) {
      generatePDF(targetRef, { filename: "invoice.pdf" }).then(() => {
        window.close();
      });
    }
  }, [invoiceData]);

  useEffect(() => {
    dispatch(getInvoices());

    return () => {
      setInvoiceData(null);
    };
  }, []);

  return (
    <div className="content-wrapper container-home">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4 col-md-12">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-md-4 text-start invoice-content-top">
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/admin/invoices");
                }}>
                <i className="far fa-arrow-left me-2"></i> Back To Listing
              </a>
            </div>
            {/* {invoiceData?.status === "No" && userInfo?.type === 3 && (
              <div
                className="col-md-4 text-start invoice-content-top"
                style={{ fontWeight: 500, fontSize: 16 }}>
                <a
                  role="button"
                  onClick={() => {
                    history.push(
                      `/wnwn/add-invoice?edit=${invoiceData?.id}`,
                    );
                  }}>
                  <i className="far fa-edit"></i> Edit
                </a>
              </div>
            )} */}
          </div>
          <div
            ref={targetRef}
            className="card-body col-md-12 col-sm-12"
            style={{
              padding: 10,
              paddingTop: window?.location?.search?.includes("&download=true")
                ? 50
                : 0,
            }}>
            <div
              className="mt-4 row"
              style={{
                paddingTop: window?.location?.search?.includes("&download=true")
                  ? 70
                  : 0,
              }}>
              <div className="col-md-6 col-sm-6 col-6 invoice-container">
                <div className="invoice-content">
                  <div>
                    <p className="desc-invoice">Invoice Date</p>
                    <span className="val-invoice">
                      {moment(invoiceData?.created_at).format("MMM DD, YYYY")}
                    </span>
                  </div>
                  <div className="mt-3">
                    <p className="desc-invoice">Invoice ID</p>
                    <span className="val-invoice">{invoiceData?.id}</span>
                  </div>
                  <div className="mt-3">
                    <p className="desc-invoice">Date Range</p>
                    <span className="val-invoice">
                      {moment(invoiceData?.start_date)?.format("MM/DD/YYY")}{" "}
                      {" - "}
                      {moment(invoiceData?.end_date)?.format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <div className="mt-3">
                    <p className="desc-invoice">Payment Date</p>
                    <span className="val-invoice">
                      {invoiceData?.payment_date
                        ? moment(invoiceData?.payment_date)?.format(
                            "MMM DD, YYYY",
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-6 invoice-container">
                <div className="invoice-content">
                  <div>
                    <p className="desc-invoice">Status</p>
                    <span
                      className="val-invoice"
                      style={{
                        color: invoiceData?.status === "Yes" ? "green" : "red",
                      }}>
                      {invoiceData?.status
                        ? invoiceData?.status === "Yes"
                          ? "Paid"
                          : "Pending"
                        : ""}
                    </span>
                  </div>
                  <div className="mt-3">
                    <p className="desc-invoice">Amount</p>
                    <span className="val-invoice">
                      ${" "}
                      {invoiceData?.amount
                        ? (
                            Number(invoiceData?.amount) -
                            Number((invoiceData?.amount / 100) * 6)
                          ).toFixed(2)
                        : "-"}
                    </span>
                  </div>
                  <div className="mt-3">
                    <p className="desc-invoice">Total Orders</p>
                    <span className="val-invoice">
                      {invoiceData?.total_orders || 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className={`col-md-8 col-12 col-sm-12 mt-4`}
                style={{
                  border: "1px solid lightgrey",
                  padding: 10,
                  borderRadius: 10,
                }}>
                <span className="desc-invoice">
                  {userInfo?.type === 2 ? "Earnings & Fees" : "Sales & Amount"}{" "}
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}>
                  <span>Total sales</span>
                  <span style={{ fontWeight: 500, fontSize: 15 }}>
                    ${Number(invoiceData?.amount)?.toFixed(2)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    paddingBottom: 10,
                    borderBottom: "1px solid lightgrey",
                  }}>
                  <span>
                    {userInfo?.type === 2 ? "Platform fees" : "Fees earned"}{" "}
                  </span>
                  <span></span>
                  <span style={{ fontWeight: 500, fontSize: 15 }}>
                    ${Number((invoiceData?.amount / 100) * 6)?.toFixed(2)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}>
                  <span>
                    {userInfo?.type === 2 ? "Your Earnings" : "Invoice amount"}{" "}
                  </span>
                  <span style={{ fontWeight: 500, fontSize: 15 }}>
                    $
                    {(
                      Number(invoiceData?.amount) -
                      Number((invoiceData?.amount / 100) * 6)
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            {invoiceData?.invoiceUrl?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}>
                <div className={`col-md-8 col-12 col-sm-12 mt-4`}>
                  <span className="desc-invoice">Attachments</span>

                  <div className="row mt-2">
                    {invoiceData?.invoiceUrl?.map((url) => (
                      <a
                        target="_blank"
                        href={url}
                        style={{ display: "flex", justifyContent: "center" }}
                        className="col-md-3 col-12 col-sm-6">
                        <img
                          src={url?.endsWith(".pdf") ? PDFImage : url}
                          className="attechments-img"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
