import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { getLocalData, inputValidator } from "../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationOptions,
  registerUser,
} from "../store/actions/authAction";
import {
  addInvoiceAction,
  getInvoices,
  getRestaurants,
  getTransactionsTotal,
  getUsers,
  uploadDocuments,
} from "../store/actions/dashboardAction";
import moment from "moment";
import DateInput from "../forms/DateInput";
import pdfImage from "../assets/images/pdf.png";

const userSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  phone: yup.string().trim().required("Phone is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
});

const invoiceType = ["Daily", "Weekly", "Monthly"];
const invoiceStatus = ["pending", "paid"];

const AddInvoiceForm = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState("Weekly");
  const [selectedDate, setDate] = useState(getDateWithType());
  const [restaurantId, setRestaurantId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formType, setFormType] = useState("form");
  const [imgUrl, setImgUrl] = useState(null);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [error, setError] = useState("");
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectInvoiceStatus, setSelectInvoiceStatus] = useState("");

  const { userInfo, restaurants } = useSelector((state) => state.dashboard);

  const userType = getLocalData("userType");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestaurants());
  }, []);

  useEffect(() => {
    setDate(getDateWithType());
  }, [selectedInvoiceType]);

  function getDateWithType() {
    const today = new Date();
    if (selectedInvoiceType === "Weekly") {
      const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
      const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Offset to Monday
      const monday = new Date(today);
      monday.setDate(today.getDate() + mondayOffset);
      return monday;
    } else if (selectedInvoiceType === "Monthly") {
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
      console.log("firstDate", firstDate);
      return firstDate;
    } else {
      return new Date();
    }
  }

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (userInfo?.id && userType == 2) setValue("orgId", userInfo.id);
  }, [userInfo, userType]);

  const continueHandler = async (e) => {
    e.preventDefault();

    const dayNumber = moment(selectedDate).daysInMonth();

    const payload = {
      startDate: moment(selectedDate)?.format("YYYY-MM-DD"),
      endDate: moment(selectedDate)
        ?.add(
          selectedInvoiceType === "Weekly"
            ? 6
            : selectedInvoiceType === "Monthly"
            ? dayNumber - 1
            : 0,
          "days",
        )
        ?.format("YYYY-MM-DD"),
      restaurantId,
    };

    setIsLoading(true);
    const res = await dispatch(getTransactionsTotal(payload));

    setFormType("price");
    setError("");

    if (res?.length) {
      setTotalAmount(
        res
          ?.map((data) => +data?.amount)
          ?.reduce((partialSum, a) => partialSum + a, 0),
      );
    } else {
      setTotalAmount(0);
    }
    setIsLoading(false);
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImgUrl(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoadingImg(true);
      getBase64(e.target.files[0]);

      var data = new FormData();

      data.append("invoiceImage", e.target.files[0]);

      const res = await dispatch(uploadDocuments(data));

      if (res?.profilePic) {
        setInvoice(res?.profilePic);
      }

      setIsLoadingImg(false);
    }
  };

  const addInvoice = async () => {
    const dayNumber = moment(selectedDate).daysInMonth();

    const payload = {
      status: selectInvoiceStatus,
      startDate: moment(selectedDate)?.format("YYYY-MM-DD"),
      endDate: moment(selectedDate)
        ?.add(
          selectedInvoiceType === "Weekly"
            ? 6
            : selectedInvoiceType === "Monthly"
            ? dayNumber - 1
            : 0,
          "days",
        )
        ?.format("YYYY-MM-DD"),
      restaurantId,
      amount: totalAmount,
      invoice,
    };

    const res = await dispatch(addInvoiceAction(payload));

    if (res?.status === "success") {
      await dispatch(getInvoices());
      closeModal();
    }
  };

  return (
    <div
      className={`authentication-wrapper add-member-modal ${
        datePickerOpen ? "add-invoice-modal" : ""
      }`}>
      <div className="form-wizard">
        {formType === "form" && (
          <form className="position-relative">
            <div className={`d-flex authentication-bg p-sm-5 p-4 login-ss`}>
              <div className="form-block mobile-margin-top">
                <div className="style-line mb-4">
                  <select
                    className="form-select form-control form-control-sm"
                    onChange={(e) => setSelectedInvoiceType(e.target.value)}>
                    <option
                      value=""
                      disabled
                      selected>
                      Invoice type
                    </option>
                    {invoiceType?.map((val) => (
                      <option
                        selected={val == selectedInvoiceType}
                        value={val}
                        key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <span className="line"></span>
                </div>
                <label>Select Date</label>
                <DateInput
                  setDate={setDate}
                  setDatePickerOpen={setDatePickerOpen}
                  date={selectedDate}
                  label="End Date"
                  type={selectedInvoiceType}
                />
                <div className="style-line">
                  <select
                    className="form-select form-control form-control-sm"
                    onChange={(e) => setRestaurantId(+e.target.value)}>
                    <option
                      value=""
                      disabled
                      selected>
                      Select store
                    </option>
                    {restaurants?.map((val) => (
                      <option
                        selected={val?.id == restaurantId}
                        value={val?.id}
                        key={val?.id}>
                        {val?.name}
                      </option>
                    ))}
                  </select>
                  <span className="line"></span>
                </div>

                <button
                  onClick={continueHandler}
                  disabled={!restaurantId || isLoading}
                  className="btn w-100 mt-3 wizard-next-btn">
                  {isLoading ? "Saving" : "Continue"}
                </button>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    marginTop: 5,
                  }}>
                  {error && !isLoading ? error : ""}
                </p>
              </div>
            </div>
          </form>
        )}

        {(formType === "price" || formType === "upload") && (
          <div
            className={`d-flex authentication-bg login-ss position-relative`}>
            <div
              className="form-block mobile-margin-top"
              style={{ paddingTop: 40 }}>
              {formType === "upload" ? (
                <div style={{ width: 340 }}>
                  {imgUrl ? (
                    <div>
                      <img
                        width={340}
                        src={imgUrl}
                      />
                      <i
                        onClick={() => {
                          setImgUrl(null);
                          setInvoice("");
                        }}
                        className="far fa-pen me-2 add-ads-edit"></i>
                    </div>
                  ) : (
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        textAlign: "center",
                        backgroundColor: "#fff",
                      }}>
                      <label
                        disabled
                        for="upload"
                        class="file-upload mb-0 image-ads-upload"
                        tabindex="0"
                        style={{
                          backgroundColor: "#eee",
                          color: "#33032F",
                        }}>
                        <i class="fa fa-upload"></i>{" "}
                        {isLoadingImg ? "Uploading..." : "Select Invoice"}
                      </label>
                      <input
                        disabled={isLoadingImg}
                        type="file"
                        id="upload"
                        accept="image/jpg, image/png, image/jpeg"
                        hidden="true"
                        onChange={uploadImage}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: 40,
                    }}>
                    <button
                      style={{ width: "48%" }}
                      onClick={() => setFormType("price")}
                      className="btn mt-3 wizard-next-btn">
                      Back
                    </button>
                    <button
                      style={{ width: "48%" }}
                      disabled={!invoice}
                      onClick={addInvoice}
                      className="btn mt-3 wizard-next-btn">
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {totalAmount ? (
                    <h4>Total Amount: ${totalAmount?.toFixed(2)}</h4>
                  ) : (
                    <h6>No Transactions Found</h6>
                  )}
                  <div className="style-line">
                    <select
                      className="form-select form-control form-control-sm"
                      onChange={(e) => setSelectInvoiceStatus(e.target.value)}>
                      <option
                        value=""
                        disabled
                        selected>
                        Select status
                      </option>
                      {invoiceStatus?.map((val) => (
                        <option
                          selected={val == selectInvoiceStatus}
                          value={val}
                          key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                    <span className="line"></span>
                  </div>
                  <div
                    className="mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <button
                      style={{ width: "48%" }}
                      onClick={() => setFormType("form")}
                      className="btn mt-3 wizard-next-btn">
                      Back
                    </button>
                    <button
                      style={{ width: "48%" }}
                      disabled={totalAmount == 0 || !selectInvoiceStatus}
                      onClick={() =>
                        selectInvoiceStatus === "paid"
                          ? setFormType("upload")
                          : addInvoice()
                      }
                      className="btn mt-3 wizard-next-btn">
                      {selectInvoiceStatus === "paid" ? "Pay" : "Save"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddInvoiceForm;
