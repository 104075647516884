import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  addInvoiceAction,
  getInvoices,
  getRestaurantsAdmin,
  getTransactionsTotal,
  updateInvoiceAction,
  uploadDocuments,
} from "../../store/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import DateInput from "../../forms/DateInput";
import moment from "moment";
import PDFImage from "../../assets/images/pdf.png";

const invoiceType = ["Daily", "Weekly", "Monthly"];
const invoiceStatus = ["Yes", "No"];

const AddNewInvoice = ({ history }) => {
  const [selectedInvoiceType, setSelectedInvoiceType] = useState("Weekly");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [img, setImg] = useState([]);
  const [error, setError] = useState("");
  const [bagDetails, setBagDetails] = useState(null);
  const [selectedDate, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedPaymentDate, setPaymentDate] = useState(new Date());
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [restaurantId, setRestaurantId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formType, setFormType] = useState("form");
  const [selectInvoiceStatus, setSelectInvoiceStatus] = useState("No");
  const [loadingAmount, setLoadingAmount] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [addNewInvoice, setAddNewInvoice] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);

  const [editInvoice, setEditInvoice] = useState(null);
  const { restaurants, invoices: invoiceList } = useSelector(
    (state) => state.dashboard,
  );

  function getDateWithType() {
    const today = new Date();
    if (selectedInvoiceType === "Weekly") {
      const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
      const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Offset to Monday
      const monday = new Date(today);
      monday.setDate(today.getDate() + mondayOffset);
      return monday;
    } else if (selectedInvoiceType === "Monthly") {
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);

      return firstDate;
    } else {
      return new Date();
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestaurantsAdmin());

    if (window.location?.search?.includes("?edit=")) {
      dispatch(getInvoices());
    }
  }, []);

  useEffect(() => {
    if (window.location?.search?.includes("?edit=")) {
      const id = window.location?.search?.replace("?edit=", "");
      setEditInvoice(id);
      const currentInvoice = invoiceList?.find((data) => data?.id == id);

      if (currentInvoice) {
        const difference = moment(currentInvoice?.end_date)?.diff(
          currentInvoice?.start_date,
          "days",
        );

        setRestaurantId(currentInvoice?.store_id);
        setSelectedInvoiceType(
          difference > 20 ? "Monthly" : difference > 5 ? "Weekly" : "Daily",
        );
        setDate(currentInvoice?.start_date);
        setSelectInvoiceStatus("No");
      }
    }
  }, [invoiceList]);

  useEffect(() => {
    if (!window.location?.search?.includes("?edit=")) {
      setDate(getDateWithType());
    }
  }, [selectedInvoiceType]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg([...img, reader.result]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setError("");
      var data = new FormData();
      data.append("invoiceImage", e.target.files[0]);

      setIsLoadingImg(true);
      const res = await dispatch(uploadDocuments(data));

      if (res?.profilePic) {
        getBase64(e.target.files[0]);
        setInvoices([...invoices, res?.profilePic]);
        setAddNewInvoice(false);
      } else {
        setError("Somthing went wrong please try again!");
      }
      setIsLoadingImg(false);
    }
  };

  const addHandler = async (e) => {
    e.preventDefault();
    setError("");
    if (selectInvoiceStatus === "Yes" && !invoices?.length) {
      return setError("Please upload at least one invoice");
    }

    const payload = {
      status: selectInvoiceStatus,
      startDate: moment(selectedDate)?.format("YYYY-MM-DD"),
      endDate: endDate,
      restaurantId,
      amount: totalAmount,
      invoice: invoices?.join(","),
      paymentDate: moment(selectedPaymentDate)?.format("YYYY-MM-DD"),
      totalOrders,
    };

    if (editInvoice) {
      payload["id"] = editInvoice;

      const res = await dispatch(updateInvoiceAction(payload));

      if (res?.status === "success") {
        await dispatch(getInvoices());
        history.push(`/wnwn/admin/invoices`);
      }
    } else {
      const res = await dispatch(addInvoiceAction(payload));

      if (res?.status === "success") {
        await dispatch(getInvoices());
        history.push("/wnwn/admin/invoices");
      }
    }
  };

  const getTotalAmount = async () => {
    const dayNumber = moment(selectedDate).daysInMonth();

    const payload = {
      startDate: moment(selectedDate)?.format("YYYY-MM-DD"),
      endDate: moment(selectedDate)
        ?.add(
          selectedInvoiceType === "Weekly"
            ? 6
            : selectedInvoiceType === "Monthly"
            ? dayNumber - 1
            : 0,
          "days",
        )
        ?.format("YYYY-MM-DD"),
      restaurantId,
    };

    setEndDate(payload.endDate);

    setLoadingAmount(true);
    const res = await dispatch(getTransactionsTotal(payload));

    setFormType("price");
    setError("");

    if (res?.length) {
      setTotalOrders(res?.length);
      setTotalAmount(
        res
          ?.map((data) => +data?.amount)
          ?.reduce((partialSum, a) => partialSum + a, 0),
      );
    } else {
      setTotalAmount(0);
    }
    setLoadingAmount(false);
  };

  useEffect(() => {
    if (restaurantId && selectedDate && invoiceType) getTotalAmount();
  }, [restaurantId, selectedDate, invoiceType]);

  return (
    <div
      className="content-wrapper container-home"
      style={{ height: "100%" }}>
      <div className="container-xxl flex-grow-1 container-p-b">
        <div
          className="card mb-3 mb-md-4 mt-4"
          style={{ height: "100%" }}>
          <div className="row">
            <div
              className="col-md-5 text-start"
              style={{
                paddingTop: 20,
                paddingLeft: 40,
              }}>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/admin/invoices");
                }}>
                <i className="far fa-arrow-left me-2"></i> Back To Listing
              </a>
            </div>
            <div
              className="col-md-5 mt-3 new-invoice"
              style={{ textAlign: "center" }}>
              <h4 style={{ width: 100, whiteSpace: "nowrap" }}>
                {editInvoice ? "Update" : "New"} Invoice
              </h4>
            </div>
          </div>

          <div className="card-body col-md-12 mt-3">
            <form
              className={`row g-4 mb-4 mb-md-5`}
              style={{ display: "flex", justifyContent: "center" }}>
              <div className="row col-md-8 mt-4">
                <div className="col-md-6">
                  <label className="mb-2">Select Invoice Type</label>
                  <div className="style-line mb-md-4 mb-sm-2 mb-2">
                    <select
                      className="form-select form-control form-control-sm"
                      onChange={(e) => {
                        setSelectedInvoiceType(e.target.value);
                        if (editInvoice) setDate(getDateWithType());
                      }}>
                      <option
                        value=""
                        disabled
                        selected>
                        Invoice type
                      </option>
                      {invoiceType?.map((val) => (
                        <option
                          selected={val == selectedInvoiceType}
                          value={val}
                          key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                    <span className="line"></span>
                  </div>
                </div>
                <div className="col-md-6 mb-sm-2 mb-2">
                  <label className="mb-2">Select Date</label>
                  <DateInput
                    setDate={setDate}
                    setDatePickerOpen={setDatePickerOpen}
                    date={selectedDate}
                    label="End Date"
                    type={selectedInvoiceType}
                  />
                </div>
                <div className="col-md-6">
                  <label className="mb-2">Select Store</label>
                  <select
                    className="form-select form-control form-control-sm"
                    onChange={(e) => setRestaurantId(+e.target.value)}>
                    <option
                      value=""
                      disabled
                      selected>
                      Select store
                    </option>
                    {restaurants?.map((val) => (
                      <option
                        selected={val?.id == restaurantId}
                        value={val?.id}
                        key={val?.id}>
                        {val?.name}
                      </option>
                    ))}
                  </select>
                  <span className="line"></span>
                </div>
                {restaurantId && (
                  <>
                    <div className="mt-3 row">
                      {totalAmount > 0 && (
                        <div className="col-md-6">
                          <div className="mt-3">
                            <p className="desc-invoice">Date Range</p>
                            <span className="val-invoice">
                              {moment(selectedDate)?.format("MM/DD/YYYY")}{" "}
                              {" - "}
                              {moment(endDate).format("MM/DD/YYYY")}
                            </span>
                          </div>
                          <div className="mt-3">
                            <p className="desc-invoice">Total Orders</p>
                            <span className="val-invoice">{totalOrders}</span>
                          </div>
                          <div className="col-md-12 row mt-2">
                            <p className="desc-invoice mt-2">
                              Is invoice paid?
                            </p>
                            {invoiceStatus?.map((type) => (
                              <div className="col-md-4 mt-1">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    name="type"
                                    type="radio"
                                    value={type}
                                    checked={type == selectInvoiceStatus}
                                    id={type}
                                    onChange={() =>
                                      setSelectInvoiceStatus(type)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for={type}>
                                    {type}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                          {selectInvoiceStatus === "Yes" && (
                            <div className="col-md-12 mt-2">
                              <label className="mb-2">
                                Select Payment Date
                              </label>
                              <DateInput
                                setDate={setPaymentDate}
                                date={selectedPaymentDate}
                                setDatePickerOpen={setDatePickerOpen}
                                label="Select Payment Date"
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {totalAmount && !loadingAmount ? (
                        <div className="col-md-6">
                          <div
                            className="col-md-12 mt-2"
                            style={{
                              border: "1px solid lightgrey",
                              padding: 10,
                              borderRadius: 10,
                            }}>
                            <span className="desc-cat">Sales & Fees</span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}>
                              <span>Total sales</span>
                              <span className="text-bold">
                                ${Number(totalAmount)?.toFixed(2)}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: 5,
                                borderBottom: "1px solid lightgrey",
                              }}>
                              <span>Fees earned</span>
                              <span className="text-bold">
                                ${Number((totalAmount / 100) * 6)?.toFixed(2)}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: 5,
                              }}>
                              <span>Amount Due</span>
                              <span className="text-bold">
                                $
                                {(
                                  Number(totalAmount) -
                                  Number((totalAmount / 100) * 6)
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <h4>Total Amount: ${totalAmount?.toFixed(2)}</h4>
                        <h6>
                          {loadingAmount
                            ? "Getting Transactions..."
                            : "No transactions found for the selected store and date range"}{" "}
                        </h6>
                      )}
                    </div>
                  </>
                )}
              </div>
              {selectInvoiceStatus === "Yes" && totalAmount ? (
                <div className="row col-md-8 mt-3">
                  {/* <label className="mb-2">Select Invoice</label> */}
                  {/* <div className="col-md-12 row mt-2">
                            <p className="desc-invoice mt-2">
                              Attachments
                            </p>
                          </div> */}
                  <div
                    className="col-md-12 row"
                    style={{
                      position: "relative",
                      display: "flex",
                      // textAlign: "center",
                      backgroundColor: "#fff",
                    }}>
                    {/* <div className="col-md-12 row mt-2">
                            <p className="desc-invoice mt-2">
                              Attachments
                            </p>
                          </div> */}
                    <div className="col-md-6">
                      <p className="desc-invoice mt-2">Attachments</p>
                      <label
                        disabled
                        for="upload"
                        tabindex="0"
                        style={{
                          cursor: "pointer",
                          color: "#80CC27",
                          width: "100%",
                        }}>
                        <i class="fa fa-upload"></i>{" "}
                        {isLoadingImg ? "Uploading..." : "Upload"}
                      </label>
                      <input
                        disabled={isLoadingImg}
                        type="file"
                        id="upload"
                        accept="image/jpg, image/png, image/jpeg, application/pdf"
                        hidden="true"
                        onChange={uploadImage}
                      />
                    </div>
                    {img?.length > 0 && (
                      <div className="col-md-12 mt-3 row">
                        {img?.map((src, i) => (
                          <div
                            className="mb-2 col-md-4 col-sm-6 attechments-img"
                            style={{
                              position: "relative",
                              backgroundColor: "#eee",
                              display: "flex",
                              justifyContent: "center",
                              borderLeft: "8px solid #fff",
                              borderRadius: 0,
                            }}>
                            <a
                              role="button"
                              style={{
                                color: "red",
                                right: 0,
                                position: "absolute",
                              }}
                              onClick={() => {
                                setImg(img?.filter((val) => val !== src));
                                setInvoices(
                                  invoices?.filter((inv, index) => index !== i),
                                );
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24">
                                <path
                                  fill="red"
                                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                />
                              </svg>
                            </a>
                            <img
                              src={
                                src?.startsWith("data:application/pdf")
                                  ? PDFImage
                                  : src
                              }
                              style={{ borderRadius: 0 }}
                              className="attechments-img"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div
                className="row col-md-8 mt-2"
                style={{ marginTop: -40 }}>
                <div className="col-md-6">
                  <button
                    onClick={() =>
                      history.push("/wnwn/admin/invoices")
                    }
                    disabled={isLoading}
                    className="btn w-100 mt-md-5 wizard-next-btn grey-btn">
                    Cancel
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    onClick={addHandler}
                    disabled={
                      loadingAmount || !totalAmount || !selectInvoiceStatus
                    }
                    className="btn w-100 mt-md-5 wizard-next-btn">
                    {editInvoice ? "Update" : "Save"}
                  </button>
                </div>
              </div>
              <p
                style={{ color: "red", fontSize: "14px", textAlign: "center" }}>
                {error ? error : ""}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewInvoice;
