import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../forms/TextField";
import { inputValidator, setLocalData } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/actions/authAction";
import PublicLayout from "../../Layouts/PublicLayout";
import LoginImage from "../../assets/images/login.jpg";

const schema = yup.object({
  email: yup.string().trim().required("Email is Required"),
  password: yup.string().trim().required("Password is Required"),
});

const Login = ({ history }) => {
  const [passwordType, setPasswordType] = useState("password");
  const { error } = useSelector((state) => state.ui);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loginHandler = async (data) => {
    console.log(data);
    // if (data.email != "admin" || data.password != "admin@123") {
    //   setLoginError("Invalid username/password");
    //   return;
    // }
    setIsLoading(true);
    const res = await dispatch(loginUser(data));
    setIsLoading(false);

    if (res?.status === "success") {
      if (res.type == 2) {
        history.push("/wnwn/admin/bags");
      } else if (res.type == 3) {
        history.push("/wnwn/admin/stores");
      }
    }
  };

  return (
    <PublicLayout history={history}>
      <div className="authentication-wrapper authentication-cover medium">
        <div className="authentication-inner row g-0">
          <div className="d-flex col-md-12 align-items-center authentication-bg p-sm-5 p-4 login-div">
            <div className="w-px-400 mx-auto mt-4">
              <h2 className="mb-2 semi">Waste Not Want Not</h2>
              {/* <h5 className="mb-2 semi">Login</h5> */}
              <br />
              {/* <p className="mb-4">
                New user?{" "}
                <a
                  role="button"
                  onClick={() => history.push("/wnwn/admin/login")}>
                  Create an acount{" "}
                </a>
              </p> */}

              <form
                onSubmit={handleSubmit(loginHandler)}
                className="mb-3">
                <TextField
                  errors={errors}
                  placeholder="Username"
                  register={register}
                  fieldName="email"
                />

                <TextField
                  inputType={passwordType}
                  errors={errors}
                  divClass="style-line mb-3 form-password-toggle"
                  placeholder="Password"
                  register={register}
                  fieldName="password"
                  icon={<i className="far fa-eye-slash"></i>}
                  viewPassword={() =>
                    setPasswordType(
                      passwordType === "password" ? "text" : "password",
                    )
                  }
                />

                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ paddingTop: 35 }}>
                  {/* <a
                    role="button"
                    onClick={() => history.push("/wnwn/forgot-password")}
                    className="col-6 semi">
                    Forgot Password?
                  </a> */}
                  <button
                    role="submit"
                    className="btn w-100"
                    disabled={isLoading}>
                    {isLoading ? "Loging in.." : "Log in"}
                  </button>
                </div>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    marginTop: 20,
                  }}>
                  {error ? error : ""}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    marginTop: 20,
                  }}>
                  {loginError ? loginError : ""}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Login;
