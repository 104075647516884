import React, { useEffect } from "react";
import { getLocalData } from "../utils/functions";

const PublicLayout = ({ children, history }) => {
  const token = getLocalData("token");

  useEffect(() => {
    const userType = getLocalData("userType");

    if (token) {
      userType == 4
        ? history.push("/wnwn/admin/profile")
        : history.push("/wnwn/admin");
    }
    // eslint-disable-next-line
  }, [token]);

  return <>{children}</>;
};

export default PublicLayout;
