import React, { useState } from "react";
import Modal from "react-modal";
import AddInvoiceForm from "./add-invoice-form";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    zIndex: 100000,
    // backgroundColor: "#1E2A42",
  },
};

const AddInvoice = ({ modalIsOpen, afterOpenModal, closeModal }) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -40,
            marginLeft: 20,
            marginRight: 20,
          }}>
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              marginBottom: -40,
            }}>
            New Invoice
          </p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <AddInvoiceForm closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default AddInvoice;
