import React from "react";
import FormTextField from "../../forms/FormTextField";
import { getLocalData } from "../../utils/functions";
import { useSelector } from "react-redux";

const Profile = () => {
  const { userInfo } = useSelector((state) => state?.dashboard);

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div
          className="card-body"
          style={{ minHeight: "100vh" }}>
          <div className="pt-4 row g-3 justify-content-between">
            {/* <div className="col-lg-3 col-md-4 col-sm-4">
              <div className="col-md-12">
                <img
                  src={userInfo?.profilePic}
                  style={{ height: 170 }}
                />
                <br />
              </div>
            </div> */}
            <div className="col-lg-8 col-md-7 col-sm-6">
              {/* <div className="col-md-12 row">
                <div className="col-md-4 tag"> Store Id :</div>
                <div className="col-md-8">
                  <p className="tag">
                    <span className="tag-val">{userInfo?.id}</span>
                  </p>
                </div>
              </div> */}
              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Name :</div>
                <div className="col-md-8">
                  <p >
                    <span >{userInfo?.name}</span>
                  </p>
                </div>
              </div>

              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Email :</div>
                <div className="col-md-8">
                  <p >
                    <span >{userInfo?.email}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Phone :</div>
                <div className="col-md-8">
                  <p >
                    <span >
                      {userInfo?.phone ? userInfo?.phone : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-12 row">
                <div className="col-md-4 tag"> Address :</div>
                <div className="col-md-8">
                  <p >
                    <span >{userInfo?.location}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
