import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBag,
  getMealDetails,
  getMeals,
  getMealsAdmin,
  getRestaurantsAdmin,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import Pagination from "./Pagination";
import { usePagination } from "../../apis/hooks";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";
import DeleteUserModal from "../../modals/delete-user";

const Meals = ({ history, ordersPage = false }) => {
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [pageType, setPageType] = useState("table");
  const [details, setDetails] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const dispatch = useDispatch();

  const { meals, userInfo } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    if (userInfo?.type === 3) {
      dispatch(getMealsAdmin());
    } else if (userInfo?.type) {
      dispatch(getMeals());
    }
  }, [userInfo?.type]);

  const pagination = usePagination(
    meals?.filter((meal) =>
      meal?.name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase()),
    )?.length,
    showDataPerPage,
  );

  useEffect(() => {
    if (meals?.length && pagination.current > 0) {
      setShowData(
        meals
          ?.filter((meal) =>
            meal?.name
              ?.toLowerCase()
              ?.includes(searchText?.toLocaleLowerCase()),
          )
          ?.slice(
            (pagination.current - 1) * showDataPerPage,
            pagination.current * showDataPerPage,
          ),
      );
    }
  }, [meals, pagination.current, searchText]);

  const getDetails = async (id) => {
    const details = showData?.find((data) => data.id === id);

    setDetails(details);
    setIsOpen(true);
  };

  const deleteMealHandler = async () => {
    if (selectedId) {
      await dispatch(deleteBag(selectedId));
      await dispatch(getMeals());
      setDeleteUserShow(false);
    }
  };

  return (
    <>
      {pageType === "table" ? (
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
                <div className="flex-row d-flex align-items-center">
                  <h5 className="mb-0 medium text-nowrap">
                    {ordersPage ? "Meals Available Quantity" : "Meals"}
                  </h5>
                  <div className="input-group ms-2 ms-md-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        pagination.goToPage(1);
                      }}
                    />
                  </div>
                </div>
                {userInfo?.type === 2 && !ordersPage && (
                  <a
                    role="button"
                    onClick={() =>
                      history.push("/wnwn/admin/add-bag")
                    }
                    className="btn">
                    + Add New Bag{" "}
                  </a>
                )}
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Name</th>
                        {ordersPage ? (
                          <>
                            <th>Total Quantity</th>
                            <th>Available</th>
                            <th>Sold</th>
                          </>
                        ) : (
                          <>
                            <th>Category</th>
                            <th>Store</th>
                            <th>Cost</th>
                            <th>Pickup</th>
                            <th></th>
                            {userInfo?.type === 2 && <th></th>}
                          </>
                        )}
                      </tr>
                    </thead>

                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((data) => (
                        <tr key={data?.id}>
                          <td>
                            {data?.image && (
                              <img
                                src={data?.image}
                                alt={data?.name}
                                className="w-px-30 h-px-30 rounded-circle me-2"
                              />
                            )}
                            {data?.name}
                          </td>
                          {ordersPage ? (
                            <>
                              <td>{data?.quantity}</td>
                              <td>{data?.quantity - data?.sold}</td>
                              <td>{data?.sold}</td>
                            </>
                          ) : (
                            <>
                              <td>{data?.category}</td>
                              <td>{data?.store}</td>
                              <td>${data?.cost?.toFixed(2)}</td>
                              <td>{data?.pickUpTime}</td>

                              <td>
                                <a
                                  role="button"
                                  onClick={() => getDetails(data?.id)}>
                                  Preview
                                </a>
                              </td>
                              {userInfo?.type === 2 && (
                                <td>
                                  <span
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/wnwn/admin/add-bag?bag-id=${data?.id}`,
                                      )
                                    }>
                                    Edit
                                  </span>
                                  <span
                                    style={{
                                      marginTop: 5,
                                      display: "block",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    role="button"
                                    onClick={() => {
                                      setDeleteUserShow(true);
                                      setSelectedId(data?.id);
                                    }}>
                                    Delete
                                  </span>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !meals?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {/* {!isLoading && !contractors?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )} */}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={meals}
                  />
                )}
              </div>
            </div>
          </div>
          <BussinessDetailsModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            details={details}
            closeModal={() => setIsOpen(false)}
            history={history}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            deleteHandler={deleteMealHandler}
            isLoading={isLoading}
            closeModal={() => setDeleteUserShow(false)}
          />
        </div>
      ) : (
        // <Profile
        //   userInfo={userInfo}
        //   setUserInfo={setUserInfo}
        //   setPageType={setPageType}
        //   editProfile={editProfile}
        //   setEditProfile={setEditProfile}
        //   getDetails={getDetails}
        // />
        <div></div>
      )}
    </>
  );
};

export default Meals;
