import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRestaurants,
  getRestaurantsAdmin,
  getUsers,
  updateStatusAction,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import organizationPic from "../../assets/img/organization.png";
import Pagination from "./Pagination";
import { usePagination } from "../../apis/hooks";
import AddMember from "../../modals/add-invoice";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";

const Restaurants = ({ user = 0 }) => {
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showName, setShowName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [editProfile, setEditProfile] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  const { restaurants, userInfo } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    if (userInfo?.type === 3) {
      dispatch(getRestaurantsAdmin());
    } else if (userInfo?.type) {
      dispatch(getRestaurants());
    }
  }, [userInfo]);

  const pagination = usePagination(
    restaurants?.filter((restaurant) =>
      restaurant?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLocaleLowerCase()),
    )?.length,
    showDataPerPage,
  );

  const allTrades = [
    "Plumbing",
    "Electrical",
    "Mechanical",
    "Building",
    "Fire",
  ];

  useEffect(() => {
    if (restaurants?.length && pagination.current > 0) {
      setShowData(
        restaurants
          ?.filter((restaurant) =>
            restaurant?.name
              ?.toLowerCase()
              ?.includes(searchText?.toLocaleLowerCase()),
          )
          ?.slice(
            (pagination.current - 1) * showDataPerPage,
            pagination.current * showDataPerPage,
          ),
      );
    }
  }, [user, restaurants, pagination.current, searchText]);

  const getDetails = async (data) => {
    setSelectedData(data);
    setPageType("profile");
    setStatus(data.status);
  };

  const saveProfileHandler = async () => {
    await dispatch(updateStatusAction({ status, id: selectedData?.id }));
    if (userInfo?.type === 3) {
      await dispatch(getRestaurantsAdmin());
    } else if (userInfo?.type) {
      await dispatch(getRestaurants());
    }
    setPageType("table");
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          {pageType === "table" ? (
            <>
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
                <div className="flex-row d-flex align-items-center">
                  <h5 className="mb-0 medium text-nowrap">Stores</h5>
                  <div className="input-group ms-2 ms-md-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        pagination.goToPage(1);
                      }}
                    />
                  </div>
                </div>
                {/* {userType != 3 && (
                  <a
                    role="button"
                    onClick={() => setIsOpen(true)}
                    className="btn">
                    + Add New Users{" "}
                  </a>
                )} */}
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th></th>
                        {/* <th>Signup Date</th> */}
                        {/* <th></th> */}
                      </tr>
                    </thead>

                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((data) => (
                        <tr key={data?.id}>
                          <td>
                            {/* <img
                              src={data?.image ? data?.image : organizationPic}
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            /> */}
                            {data?.name}
                          </td>
                          <td>{data?.email}</td>
                          <td>{`${data?.location}`}</td>
                          <td>{data?.phone ? data?.phone : "-"}</td>
                          <td
                            className={`text-${
                              data?.status === 0
                                ? "40"
                                : data?.status === 1
                                ? "success"
                                : "danger"
                            }`}>
                            {data?.status === 0
                              ? "Pending"
                              : data?.status === 1
                              ? "Accepted"
                              : data?.status === -1
                              ? "Rejected"
                              : ""}
                          </td>

                          <td>
                            <a
                              role="button"
                              onClick={() => getDetails(data)}>
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !restaurants?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {/* {!isLoading && !contractors?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )} */}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={restaurants}
                  />
                )}
              </div>
              <AddMember
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                closeModal={() => setIsOpen(false)}
              />
              <ConnectUser
                modalIsOpen={modalIsOpenUser}
                setIsOpen={setIsOpenUser}
                userId={userId}
                showName={showName}
                closeModal={() => setIsOpenUser(false)}
              />
              <AdminChangePassword
                modalIsOpen={changePasswordMember}
                setIsOpen={setChangePasswordMember}
                userId={userId}
                closeModal={() => setChangePasswordMember(false)}
              />
              <DeleteUserModal
                modalIsOpen={deleteUserShow}
                setIsOpen={setDeleteUserShow}
                userId={userId}
                userType={4}
                closeModal={() => setDeleteUserShow(false)}
              />
            </>
          ) : (
            <div
              style={{
                paddingTop: 20,
                paddingLeft: 20,
              }}>
              <a
                role="button"
                onClick={() => {
                  setPageType("table");
                }}>
                <i className="far fa-arrow-left me-2"></i> Back
              </a>

              <div className={`row g-4 mt-4 col-mt-12`}>
                <div className={`col-md-4`}>
                  <label className="text-muted small mb-2">Name</label>
                  <div className="text-40">
                    {selectedData?.name ? selectedData?.name : "-"}
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <label className="text-muted small mb-2">Email</label>
                  <div className="text-40">
                    {selectedData?.email ? selectedData?.email : "-"}
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <label className="text-muted small mb-2">Address</label>
                  <div className="text-40">
                    {selectedData?.address ? selectedData?.address : "-"}
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <label className="text-muted small mb-2">Status</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 40,
                    }}>
                    <div
                      className={`text-${
                        selectedData?.status === 0
                          ? "40"
                          : selectedData?.status === 1
                          ? "success"
                          : "danger"
                      }`}>
                      {selectedData?.status === 0
                        ? "Pending"
                        : selectedData?.status === 1
                        ? "Accepted"
                        : selectedData?.status === -1
                        ? "Rejected"
                        : ""}
                    </div>

                    {selectedData?.status === 0 && (
                      <ul
                        className="status-div"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <li>
                          <input
                            type="radio"
                            id="f-option"
                            name="selector"
                            onClick={() => setStatus(1)}
                            checked={status === 1}
                          />
                          <label for="f-option">Approve</label>
                          <div className="check"></div>
                        </li>

                        <li>
                          <input
                            type="radio"
                            id="s-option"
                            name="selector"
                            checked={status === -1}
                            onClick={() => setStatus(-1)}
                          />
                          <label
                            for="s-option"
                            className="reject-label">
                            Reject
                          </label>
                          <div className="reject"></div>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="row col-12 col-md-6 mb-4"
                style={{ marginTop: -40 }}>
                <div className="col-md-6">
                  <button
                    onClick={saveProfileHandler}
                    disabled={isLoading}
                    className="btn w-100 mt-5 wizard-next-btn">
                    {isLoading ? "Updating" : "Update"}
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    onClick={() => {
                      setPageType("table");
                      setSelectedData(null);
                    }}
                    disabled={isLoading}
                    className="btn w-100 mt-5 wizard-next-btn grey-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Restaurants;
