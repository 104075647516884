import {
  URL,
  GET_USERS,
  GET_ADS,
  UPLOAD_PROFILE,
  ADS_UPDATE,
  UPLOAD_DOCUMENT,
  CONNECTED_BUSINESS,
  GET_DISPLAY_ADS,
  GET_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_USER,
  UPLOAD_BANNER,
  DELETE_DOCUMENT,
} from "../../apis/routes";
import ApiService from "../../services/ApiServices";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";
import { setUserToken } from "./authAction";
import { setUIKey } from "./uiAction";

export const setDashboardData = (data) => ({
  type: SET_DASHBOARD_DATA,
  payload: data,
});

export const getUsers =
  (type = 0) =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));
      const res = await ApiService.get(`${URL}/${GET_USERS}/${type}`);
      dispatch(setUIKey("isLoading", false));

      if (res?.data) {
        if (type === 0) dispatch(setDashboardData({ contractors: res.data }));
        if (type === 1) dispatch(setDashboardData({ inspectors: res.data }));
      }

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const getRestaurants = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/restaurant`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ restaurants: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getAllUsers =
  (page = 1, type =0, save = true) =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));
      const res = await ApiService.get(`${URL}/users/${page};${type}`);
      dispatch(setUIKey("isLoading", false));

      if (res?.data && save) {
        dispatch(
          setDashboardData({
            users: {
              data: res.data.users,
              currentPage: res.data.currentPage,
              totalPages: res.data.totalPages,
              totalCount: res.data.totalCount,
            },
          }),
        );
      }

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };
export const updateStatusAction = (body) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/admin-status`, body);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRestaurantsAdmin = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin-stores`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (res?.data?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (res?.data?.length) {
      dispatch(setDashboardData({ restaurants: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getMeals = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/bag`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ meals: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getMealsAdmin = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin-meals`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.length) {
      dispatch(setDashboardData({ meals: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getMealDetails = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/bag/${id}`);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteBag = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/delete-bag/${id}`);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getOrders = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    dispatch(setDashboardData({ myOrders: [] }));

    const res = await ApiService.get(`${URL}/order`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ myOrders: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTodaysOrders = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    dispatch(setDashboardData({ myOrders: [] }));

    const res = await ApiService.get(`${URL}/orders/today`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ myOrders: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getOrdersAdmin = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin-orders`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (res?.data) {
      dispatch(setDashboardData({ orders: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCodeDocuments = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/${GET_DOCUMENTS}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ codeDocuments: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/${GET_USERS}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ userInfo: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getAds = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/${GET_ADS}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ ads: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRequests = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/all-request`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ requests: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getScheduleCalls = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/videocall/schedule`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ scheduleCalls: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getTransactions = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/transactions`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ transactions: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getInvoices = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/invoices`);
    dispatch(setUIKey("isLoading", false));

    console.log(res?.data);

    if (res?.data) {
      dispatch(setDashboardData({ invoices: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTransactionsTotal = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/transactions`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const confirmPickupAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/order/complete`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const orderCancelAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/order/cancel`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addInvoiceAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/invoice`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateInvoiceAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/edit-invoice`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getConnectedBusiness = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${CONNECTED_BUSINESS}`);

    if (res?.data) {
      dispatch(setDashboardData({ connectedBussiness: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDisplayAds = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_DISPLAY_ADS}`);

    if (res?.data) {
      dispatch(setDashboardData({ displayAds: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addNewDocument = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${ADD_DOCUMENT}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteUser = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${DELETE_USER}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateAds = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${ADS_UPDATE}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateProfilePhoto = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/${UPLOAD_PROFILE}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadDocuments = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/upload-invoice`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadBagImage = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/bag/photo`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addNewAd = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${GET_ADS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addNewBag = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/bag`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const updateBagDetails = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/bag`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateAdsPhoto = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/${UPLOAD_BANNER}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const deleteDocumentAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(
      `${URL}/${DELETE_DOCUMENT}`,
      data,
    );

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
