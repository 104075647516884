import React from "react";
import organizationPic from "../assets/img/organization.png";

const BussinessDetails = ({
  details,
  confirmHandler,
  closeModal,
  isLoading,
}) => {
  return (
    <div className="buss-details-modal">
      {confirmHandler && (
        <p
          style={{
            position: "absolute",
            fontSize: 18,
            fontWeight: "bold",
            left: 40,
            top: 20,
          }}>
          Review your selection
        </p>
      )}
      <div className={`col-md-12 img-center ${confirmHandler ? "mt-2" : ""}`}>
        <img
          className="buss-details-img"
          src={details?.image ? details?.image : organizationPic}
        />
      </div>
      {confirmHandler ? (
        <div className={`col-md-12 mt-4 row buss-details-content`}>
          <div className="col-md-6 mt-2">
            <span className="desc-cat">Category</span>
            <span>{details?.category}</span>
          </div>
          <div className="col-md-6 mt-2">
            <span className="desc-cat">Name</span>
            <span>{details?.name}</span>
          </div>
          <div className="col-md-12 mt-2">
            <span className="desc-cat">Description</span>
            <span>{details?.description}</span>
          </div>
          <div className="col-md-12 mt-2">
            <span className="desc-cat">Days of week</span>
            <span>{details?.selectedDays?.join(" | ")}</span>
          </div>
          <div className="col-md-12 mt-2">
            <span className="desc-cat">Collection Time</span>
            <span>{details?.pickUpTime ? details?.pickUpTime : "-"}</span>
          </div>

          <div className={`col-md-12 mt-2 earinigs-box`}>
            <span className="desc-cat">Earnings & Fees</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <span>Price</span>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                ${Number(details?.cost)?.toFixed(2)}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <span>Fee Per Bag Sold</span>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                ${Number((details?.cost / 100) * 6)?.toFixed(2)}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <span>Your Earnings</span>
              <span style={{ fontWeight: "bold", color: "#000" }}>
                $
                {(
                  Number(details?.cost) - Number((details?.cost / 100) * 6)
                ).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="row col-md-12">
            <div className="col-md-6">
              <button
                onClick={closeModal}
                disabled={isLoading}
                className="btn w-100 mt-md-5 wizard-next-btn grey-btn">
                Edit
              </button>
            </div>
            <div className="col-md-6">
              <button
                onClick={confirmHandler}
                disabled={isLoading}
                className="btn w-100 mt-md-5 wizard-next-btn">
                {isLoading ? "Saving" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={`col-md-12 mt-4 row buss-details-content`}>
          <div className={`col-md-8`}>
            <h2 style={{ fontSize: 20, fontWeight: 600 }}>
              {details?.name ? details?.name : "-"}
            </h2>
          </div>

          <div className="col-md-4 rating-box">
            <i
              style={{ color: "gold", paddingRight: 5 }}
              className="fas fa-star"></i>
            <span style={{ color: "#000", fontSize: 15, fontWeight: "bold" }}>
              {details?.star_rating
                ? Number(details?.star_rating)?.toFixed(
                    1,
                  )
                : ""}
            </span>
            <span
              className="text-muted"
              style={{ fontSize: 14 }}>
              {details?.reviewsCout
                ? ` (${details?.reviewsCout}) Reviews`
                : "No ratings"}
            </span>
          </div>
          <div
            className="col-md-9 address-box"
            style={{ marginTop: -10 }}>
            <span
              className="text-muted"
              style={{ display: "block" }}>
              {details?.address ? details?.address : "-"}
            </span>
            <span
              className="text-muted mt-1"
              style={{ display: "block" }}>
              {details?.pickUpTime ? details?.pickUpTime : "-"}
            </span>
          </div>

          <div className="col-md-3 price-box">
            <h4 style={{ fontSize: 18, fontWeight: 600 }}>
              ${details?.cost ? Number(details?.cost).toFixed(2) : "-"}
            </h4>
          </div>

          <div className={`col-md-12 mt-3 description-box`}>
            <span className="desc-cat">What You Could Get</span>
            <span className="mt-1">
              {details?.description ? details?.description : "-"}
            </span>
          </div>
          <div className={`col-md-12 mt-3 description-box`}>
            <span className="desc-cat">What You Need To Know</span>
            <span className="mt-1">
              The store will provide packaging for your food, but please bring
              your own bag to carry it home.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BussinessDetails;
