import React, { useEffect, useState } from "react";
import defaultPic from "../../assets/img/avatars/1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPickupAction,
  getOrders,
  getOrdersAdmin,
  getTodaysOrders,
  orderCancelAction,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import Pagination from "./Pagination";
import { usePagination } from "../../apis/hooks";
import AddMember from "../../modals/add-invoice";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";
import Meals from "./meals";

const Orders = () => {
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showName, setShowName] = useState(null);
  const [pageType, setPageType] = useState("todays");
  const [searchText, setSearchText] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [confirmPickup, setConfirmPickup] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const dispatch = useDispatch();

  const { myOrders, orders, userInfo } = useSelector(
    (state) => state.dashboard,
  );
  const { isLoading } = useSelector((state) => state.ui);

  const pagination = usePagination(
    selectedKey || userInfo?.type === 2
      ? userInfo?.type === 3
        ? orders && selectedKey && orders[selectedKey]?.length
        : myOrders?.filter((order) =>
            order?.meal?.toLowerCase()?.includes(searchText?.toLowerCase()),
          )?.length
      : orders &&
          Object.keys(orders)?.filter((key) =>
            searchText
              ? key?.toLowerCase()?.includes(searchText?.toLowerCase())
              : true,
          )?.length,
    showDataPerPage,
  );

  useEffect(() => {
    if (userInfo?.type === 3) {
      dispatch(getOrdersAdmin());
    } else if (userInfo?.type && pageType === "todays") {
      dispatch(getTodaysOrders());
    } else if (userInfo?.type && pageType === "history") {
      dispatch(getOrders());
    }
  }, [userInfo, pageType]);

  const confirmHandler = async () => {
    if (confirmPickup) {
      await dispatch(confirmPickupAction({ id: selectedId }));
      setConfirmPickup(false);
    }

    if (confirmCancel) {
      await dispatch(orderCancelAction({ id: selectedId }));
      setConfirmCancel(false);
    }

    await dispatch(getOrders());
    setDeleteUserShow(false);
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          {selectedKey && (
            <div
              className="col-md-4 text-start"
              style={{
                paddingTop: 20,
                paddingLeft: 20,
              }}>
              <a
                role="button"
                onClick={() => {
                  setSelectedKey("");
                  pagination.goToPage(1);
                }}>
                <i className="far fa-arrow-left me-2"></i> Back To All Orders
              </a>
            </div>
          )}
          <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
            <div className="flex-row d-flex align-items-center">
              <h5 className="mb-0 medium text-nowrap">
                {!selectedKey && <span> Orders </span>}
                {selectedKey && orders && (
                  <>
                    <span> {selectedKey} </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: 14,
                        marginTop: 5,
                        fontWeight: "lighter",
                      }}>
                      {orders[selectedKey]?.[0]?.store}
                    </span>
                  </>
                )}
              </h5>
              {!selectedKey && (
                <div className="input-group ms-2 ms-md-4">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text border-0 h-100"
                      id="basic-addon1">
                      <i className="far fa-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control border-0 ps-0"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            {/* {userType != 3 && (
                  <a
                    role="button"
                    onClick={() => setIsOpen(true)}
                    className="btn">
                    + Add New Users{" "}
                  </a>
                )} */}
          </div>
          <div className="card-body">
            {userInfo?.type == 2 && (
              <div style={{ display: "flex", fontWeight: 500, fontSize: 16 }}>
                <p
                  className={`orders-filters ${
                    pageType === "todays" ? "active" : ""
                  }`}
                  onClick={() => setPageType("todays")}>
                  Today Orders
                </p>
                <p
                  className={`orders-filters ${
                    pageType === "history" ? "active" : ""
                  }`}
                  onClick={() => setPageType("history")}
                  style={{ marginLeft: 15 }}>
                  Orders History
                </p>
              </div>
            )}

            <div className="table-responsive-xs text-nowrap mb-4">
              <table className="table table-borderless medium data-table-block">
                <thead>
                  {selectedKey || userInfo?.type === 2 ? (
                    <tr>
                      {userInfo?.type === 2 && <th>Meal</th>}
                      <th>Order Id</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      {userInfo?.type === 2 && <th>Pickup time</th>}
                      <th>Date</th>
                      <th>Name</th>
                      <th>Status</th>
                      {userInfo?.type === 2 && <th></th>}
                    </tr>
                  ) : (
                    <tr>
                      <th>Meal</th>
                      <th>Store</th>
                      <th>Total Orders</th>
                      <th></th>
                    </tr>
                  )}
                </thead>

                {selectedKey || userInfo?.type === 2 ? (
                  <tbody className="table-border-bottom-0 text-1e">
                    {userInfo?.type === 3
                      ? orders &&
                        selectedKey &&
                        orders[selectedKey]
                          ?.slice(
                            (pagination.current - 1) * showDataPerPage,
                            pagination.current * showDataPerPage,
                          )
                          ?.map((data) => (
                            <tr key={data?.id}>
                              <td>{data?.id}</td>
                              <td>{data?.quantity}</td>
                              <td>${data?.cost?.toFixed(2)}</td>
                              <td>
                                {moment(new Date(data?.createdAt)).format(
                                  "MM/DD/YYYY hh:mm A",
                                )}
                              </td>
                              <td>{data?.customer}</td>
                              {/* <td>{`${data?.address}`}</td> */}
                              <td
                                style={{
                                  color:
                                    data?.status?.toLowerCase() === "completed"
                                      ? "green"
                                      : data?.status?.toLowerCase() ===
                                        "pending"
                                      ? "orange"
                                      : data?.status?.toLowerCase() ===
                                        "canceled"
                                      ? "red"
                                      : "",
                                }}>
                                {data?.status === "pending"
                                  ? "Pending"
                                  : data?.status === "completed"
                                  ? "Picked up"
                                  : "Canceled"}
                              </td>
                            </tr>
                          ))
                      : myOrders
                          ?.filter((order) =>
                            order?.meal
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase()),
                          )
                          ?.slice(
                            (pagination.current - 1) * showDataPerPage,
                            pagination.current * showDataPerPage,
                          )
                          ?.map((data) => (
                            <tr key={data?.id}>
                              {userInfo?.type === 2 && <td>{data?.meal}</td>}
                              <td>{data?.id}</td>
                              <td>{data?.quantity}</td>
                              <td>${data?.cost?.toFixed(2)}</td>
                              {userInfo?.type === 2 && (
                                <td>{data?.pickUpTime}</td>
                              )}
                              <td>
                                {moment(new Date(data?.createdAt)).format(
                                  "MM/DD/YYYY hh:mm A",
                                )}
                              </td>
                              <td>{data?.customer}</td>
                              <td
                                style={{
                                  color:
                                    data?.status?.toLowerCase() === "completed"
                                      ? "green"
                                      : data?.status?.toLowerCase() ===
                                        "pending"
                                      ? "orange"
                                      : data?.status?.toLowerCase() ===
                                        "canceled"
                                      ? "red"
                                      : "",
                                }}>
                                {data?.status}
                              </td>
                              {userInfo?.type === 2 &&
                                data?.status?.toLowerCase() === "pending" &&
                                pageType === "todays" && (
                                  <>
                                    <td style={{ textAlign: "center" }}>
                                      <span
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setDeleteUserShow(true);
                                          setSelectedId(data?.id);
                                          setConfirmPickup(true);
                                          setConfirmCancel(false);
                                        }}>
                                        Confim Pickup
                                      </span>
                                      <span
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                          display: "block",
                                          marginTop: 5,
                                        }}
                                        onClick={() => {
                                          setDeleteUserShow(true);
                                          setSelectedId(data?.id);
                                          setConfirmCancel(true);
                                          setConfirmPickup(false);
                                        }}>
                                        Cancel Order
                                      </span>
                                    </td>
                                  </>
                                )}
                            </tr>
                          ))}
                  </tbody>
                ) : (
                  <tbody className="table-border-bottom-0 text-1e">
                    {orders &&
                      Object.keys(orders)
                        ?.filter((key) =>
                          searchText
                            ? key
                                ?.toLowerCase()
                                ?.includes(searchText?.toLowerCase())
                            : true,
                        )
                        ?.slice(
                          (pagination.current - 1) * showDataPerPage,
                          pagination.current * showDataPerPage,
                        )
                        ?.map((key) => (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{orders[key]?.[0]?.store}</td>
                            <td>{orders[key]?.length}</td>

                            <td>
                              <a
                                role="button"
                                onClick={() => setSelectedKey(key)}>
                                View All
                              </a>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                )}
              </table>
              {isLoading && !orders?.length && (
                <div className="loading-text">
                  <p>Loading...</p>
                </div>
              )}
              {/* {!isLoading && !contractors?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )} */}
            </div>
            {pagination?.totalPages > 1 && (
              <Pagination
                pagination={pagination}
                data={
                  selectedKey || userInfo?.type === 2
                    ? userInfo?.type === 3
                      ? orders && selectedKey && orders[selectedKey]
                      : myOrders?.filter((order) =>
                          order?.meal
                            ?.toLowerCase()
                            ?.includes(searchText?.toLowerCase()),
                        )
                    : orders &&
                      Object.keys(orders)?.filter((key) =>
                        searchText
                          ? key
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase())
                          : true,
                      )
                }
              />
            )}
          </div>
        </div>
      </div>
      {userInfo?.type === 2 && <Meals ordersPage={true} />}
      <AddMember
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        closeModal={() => setIsOpen(false)}
      />
      <ConnectUser
        modalIsOpen={modalIsOpenUser}
        setIsOpen={setIsOpenUser}
        userId={userId}
        showName={showName}
        closeModal={() => setIsOpenUser(false)}
      />
      <AdminChangePassword
        modalIsOpen={changePasswordMember}
        setIsOpen={setChangePasswordMember}
        userId={userId}
        closeModal={() => setChangePasswordMember(false)}
      />
      <DeleteUserModal
        modalIsOpen={deleteUserShow}
        setIsOpen={setDeleteUserShow}
        deleteHandler={confirmHandler}
        isLoading={isLoading}
        confirmPickup={confirmPickup}
        confirmCancel={confirmCancel}
        closeModal={() => setDeleteUserShow(false)}
      />
    </div>
  );
};

export default Orders;
