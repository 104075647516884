import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAds,
  getRestaurantsAdmin,
  getTransactions,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import { usePagination } from "../../apis/hooks";
import { showDataPerPage } from "../../utils/functions";
import Pagination from "./Pagination";

const Transactions = () => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState("");

  const { transactions, restaurants } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getRestaurantsAdmin());
    dispatch(getTransactions());
  }, []);

  const pagination = usePagination(
    selectedKey
      ? transactions[selectedKey].length
      : transactions && Object.keys(transactions)?.length,
    showDataPerPage,
  );

  const getStore = (id) => {
    const restaurant = restaurants?.find((restaurant) => restaurant?.id == id);

    return restaurant;
  };

  const totalAmount = (arr) => {
    const total = arr
      ?.map((transaction) => transaction?.amount)
      ?.reduce((val, old) => val + old);

    return total ? Number(total)?.toFixed(2) : total;
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          {selectedKey && (
            <div
              className="col-md-4 text-start"
              style={{
                paddingTop: 20,
                paddingLeft: 20,
              }}>
              <a
                role="button"
                onClick={() => {
                  setSelectedKey("");
                  pagination.goToPage(1);
                }}>
                <i className="far fa-arrow-left me-2"></i> Back
              </a>
            </div>
          )}
          <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
            <div className="flex-row d-flex align-items-center">
              <h5 className="mb-0 medium text-nowrap">
                {getStore(selectedKey)?.name || ""} Transactions
                <br />
                <span style={{ fontWeight: 300, fontSize: 14 }}>
                  {" "}
                  {transactions?.[selectedKey]?.[0]?.storeName}
                </span>
              </h5>

              {/* <div className="input-group ms-2 ms-md-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                    />
                  </div> */}
            </div>

            {/* <a
                  role="button"
                  onClick={() => {
                    setIsOpen(true);
                    setUpdateModal(null);
                  }}
                  className="btn">
                  + New{" "}
                </a> */}
          </div>
          <div className="card-body">
            <div className="table-responsive-xs text-nowrap mb-4">
              <table className="table table-borderless medium data-table-block">
                <thead>
                  {selectedKey ? (
                    <tr>
                      <th>Order Id</th>
                      <th>Amount</th>
                      {/* <th>User Id</th> */}
                      {/* <th>Store</th> */}
                      {/* <th>Status</th> */}
                      <th>Date</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>Store Name</th>
                      <th>Store Address</th>
                      <th>Total Transactions</th>
                      <th>Total Amount</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                {selectedKey ? (
                  <tbody className="table-border-bottom-0 text-1e">
                    {selectedKey &&
                      transactions[selectedKey]
                        ?.slice(
                          (pagination.current - 1) * showDataPerPage,
                          pagination.current * showDataPerPage,
                        )
                        ?.map((transaction) => (
                          <tr key={transaction?.id}>
                            <td>{transaction?.order_id || "-"}</td>
                            <td>${transaction?.amount?.toFixed(2) || "-"}</td>
                            {/* <td>{transaction?.user_id || "-"}</td> */}
                            {/* <td>{transaction?.storeName || "-"}</td> */}
                            {/* <td
                            style={{
                              color: transaction?.status == 2 ? "green" : "red",
                            }}>
                            {transaction?.status == 1
                              ? "failed"
                              : "completed" || "-"}
                          </td> */}
                            <td>
                              {moment(transaction?.created_at).format(
                                "MM/DD/YYYY hh:mm A",
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                ) : (
                  <tbody className="table-border-bottom-0 text-1e">
                    {transactions &&
                      Object.keys(transactions)
                        ?.slice(
                          (pagination.current - 1) * showDataPerPage,
                          pagination.current * showDataPerPage,
                        )
                        ?.map((store) => (
                          <tr key={store}>
                            <td>{store}</td>
                            <td>{transactions[store]?.[0]?.address || "-"}</td>
                            <td>{transactions[store]?.length}</td>
                            <td>${totalAmount(transactions[store])}</td>
                            <td>
                              <a
                                role="button"
                                onClick={() => setSelectedKey(store)}>
                                View All
                              </a>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                )}
              </table>
            </div>

            {pagination?.totalPages > 1 && (
              <Pagination
                pagination={pagination}
                data={
                  transactions && selectedKey
                    ? transactions[selectedKey]
                    : transactions && Object.keys(transactions)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
