import React, { useEffect } from "react";
import { getLocalData } from "../utils/functions";

const PrivateLayout = ({ children, history }) => {
  const token = getLocalData("token");

  useEffect(() => {
    if (!token) {
      history.push("/wnwn/admin/login");
    }
    // eslint-disable-next-line
  }, [token]);

  return <>{children}</>;
};

export default PrivateLayout;
